import { ReactNode } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "../redux/hooks";
import { UserType } from "../types";
import Error401Page from "../pages/Error401Page";
import ErrorNotInvitedPage from "../pages/ErrorNotInvitedPage";

const PrivateRoute = ({
  allowedUserTypes,
  allowedUserIds,
  extendAllowedUserIdsWithParamList,
  children,
}: {
  allowedUserTypes?: UserType[];
  allowedUserIds?: string[];
  extendAllowedUserIdsWithParamList?: string[];
  children: ReactNode;
}) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const loggedIn = useAppSelector((state) => state.auth.isAuthenticated);
  const userAuth = useAppSelector((state) => state.auth.user);
  const params = useParams();

  const userIds = [...(allowedUserIds ?? [])];

  extendAllowedUserIdsWithParamList?.forEach((param) => {
    const value = params[param];

    if (value) {
      userIds.push(value);
    }
  });

  if (!isAuthenticated && !isLoading) {
    // Redirect if not logged in
    return <Navigate to="/" />;
  } else if (!loggedIn || !userAuth) {
    // Show nothing while user data is fetched
    return;
  } else if (userAuth.userType.includes("Admin")) {
    // Always permitted, intentionally empty
  } else if (
    allowedUserTypes?.some((type) => userAuth.userType.includes(type))
  ) {
    // If the user has the appropriate role, approved
  } else if (userIds?.includes(userAuth.id)) {
    // If the user is explicitly authorized, approved
  } else if (!userAuth?.userType.length) {
    return <ErrorNotInvitedPage />;
  } else {
    // Show 404 if the user wasn't otherwise authorized
    return <Error401Page />;
  }

  return children;
};

export default PrivateRoute;
