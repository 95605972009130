import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { Spinner } from "@fluentui/react-components";

const Landing = () => {
  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Venturous";
  }, []);

  useEffect(() => {
    if (!user) {
      // Fetch from server
      return;
    } else if (
      user.userType.includes("Admin") ||
      user.userType.includes("Candidate")
    ) {
      navigate("/profile/edit");
    } else if (user.userType.includes("Company")) {
      navigate("/opening");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div className="h-full flex flex-col gap-8 justify-center items-center">
      <Spinner size="huge" />
      <div className="flex flex-col gap-1 text-center">
        <p className="text-2xl leading-6 font-semibold tracking-[-0.18px] text-[#22234A]">
          Welcome to Venturous
        </p>
        <p className="text-base font-medium text-[#727E94]">
          We&apos;re fetching your information...
        </p>
      </div>
    </div>
  );
};

export default Landing;
