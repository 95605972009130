export const LocationSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    fill?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M7.99968 8.95273C9.14826 8.95273 10.0794 8.02162 10.0794 6.87303C10.0794 5.72445 9.14826 4.79333 7.99968 4.79333C6.85109 4.79333 5.91998 5.72445 5.91998 6.87303C5.91998 8.02162 6.85109 8.95273 7.99968 8.95273Z"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
      />
      <path
        d="M2.4133 5.66004C3.72664 -0.113291 12.28 -0.106624 13.5866 5.66671C14.3533 9.05338 12.2466 11.92 10.4 13.6934C9.05997 14.9867 6.93997 14.9867 5.5933 13.6934C3.7533 11.92 1.64664 9.04671 2.4133 5.66004Z"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const LinkedInSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_14875_470)">
        <path
          d="M14.8156 0H1.18125C0.528125 0 0 0.515625 0 1.15313V14.8438C0 15.4813 0.528125 16 1.18125 16H14.8156C15.4688 16 16 15.4813 16 14.8469V1.15313C16 0.515625 15.4688 0 14.8156 0ZM4.74687 13.6344H2.37188V5.99687H4.74687V13.6344ZM3.55938 4.95625C2.79688 4.95625 2.18125 4.34062 2.18125 3.58125C2.18125 2.82188 2.79688 2.20625 3.55938 2.20625C4.31875 2.20625 4.93437 2.82188 4.93437 3.58125C4.93437 4.3375 4.31875 4.95625 3.55938 4.95625ZM13.6344 13.6344H11.2625V9.92188C11.2625 9.0375 11.2469 7.89687 10.0281 7.89687C8.79375 7.89687 8.60625 8.8625 8.60625 9.85938V13.6344H6.2375V5.99687H8.5125V7.04063H8.54375C8.85938 6.44063 9.63438 5.80625 10.7875 5.80625C13.1906 5.80625 13.6344 7.3875 13.6344 9.44375V13.6344Z"
          fill="#22234A"
        />
      </g>
      <defs>
        <clipPath id="clip0_14875_470">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SocialIconSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_14875_475)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.5816 0 0 3.5872 0 8.0136C0 11.5536 2.292 14.5576 5.4712 15.6168C5.8712 15.6904 6.0168 15.4432 6.0168 15.2304C6.0168 15.0408 6.0104 14.536 6.0064 13.868C3.7808 14.352 3.3112 12.7936 3.3112 12.7936C2.948 11.8672 2.4232 11.6208 2.4232 11.6208C1.6968 11.1248 2.4784 11.1344 2.4784 11.1344C3.2808 11.1904 3.7032 11.96 3.7032 11.96C4.4168 13.184 5.576 12.8304 6.0312 12.6256C6.1048 12.108 6.3112 11.7552 6.54 11.5552C4.764 11.3528 2.896 10.6648 2.896 7.5944C2.896 6.72 3.208 6.004 3.7192 5.444C3.6368 5.2416 3.3624 4.4264 3.7976 3.324C3.7976 3.324 4.4696 3.108 5.9976 4.1448C6.65021 3.96681 7.32355 3.87615 8 3.8752C8.68 3.8784 9.364 3.9672 10.0032 4.1448C11.5304 3.108 12.2008 3.3232 12.2008 3.3232C12.6376 4.4264 12.3624 5.2416 12.2808 5.444C12.7928 6.004 13.1032 6.72 13.1032 7.5944C13.1032 10.6728 11.232 11.3504 9.4504 11.5488C9.7376 11.796 9.9928 12.2848 9.9928 13.0328C9.9928 14.1032 9.9832 14.968 9.9832 15.2304C9.9832 15.4448 10.1272 15.6944 10.5336 15.616C12.1266 15.0817 13.5115 14.0602 14.4924 12.696C15.4733 11.3318 16.0007 9.69385 16 8.0136C16 3.5872 12.4176 0 8 0Z"
          fill="#22234A"
        />
      </g>
      <defs>
        <clipPath id="clip0_14875_475">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DocumentTextSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V7C2.25 3.35 4.35 1.25 8 1.25H16C19.65 1.25 21.75 3.35 21.75 7V17C21.75 20.65 19.65 22.75 16 22.75ZM8 2.75C5.14 2.75 3.75 4.14 3.75 7V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V7C20.25 4.14 18.86 2.75 16 2.75H8Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M12 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M16 17.75H8C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H16C16.41 16.25 16.75 16.59 16.75 17C16.75 17.41 16.41 17.75 16 17.75Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const DocumentTextBoldSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M10.6667 1.33337H5.33333C3 1.33337 2 2.66671 2 4.66671V11.3334C2 13.3334 3 14.6667 5.33333 14.6667H10.6667C13 14.6667 14 13.3334 14 11.3334V4.66671C14 2.66671 13 1.33337 10.6667 1.33337ZM5.33333 8.16671H8C8.27333 8.16671 8.5 8.39337 8.5 8.66671C8.5 8.94004 8.27333 9.16671 8 9.16671H5.33333C5.06 9.16671 4.83333 8.94004 4.83333 8.66671C4.83333 8.39337 5.06 8.16671 5.33333 8.16671ZM10.6667 11.8334H5.33333C5.06 11.8334 4.83333 11.6067 4.83333 11.3334C4.83333 11.06 5.06 10.8334 5.33333 10.8334H10.6667C10.94 10.8334 11.1667 11.06 11.1667 11.3334C11.1667 11.6067 10.94 11.8334 10.6667 11.8334ZM12.3333 6.16671H11C9.98667 6.16671 9.16667 5.34671 9.16667 4.33337V3.00004C9.16667 2.72671 9.39333 2.50004 9.66667 2.50004C9.94 2.50004 10.1667 2.72671 10.1667 3.00004V4.33337C10.1667 4.79337 10.54 5.16671 11 5.16671H12.3333C12.6067 5.16671 12.8333 5.39337 12.8333 5.66671C12.8333 5.94004 12.6067 6.16671 12.3333 6.16671Z"
        fill={props.stroke ?? "#22234A"}
      />
    </svg>
  );
};

export const TickCircleSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10 1.66663C5.40835 1.66663 1.66669 5.40829 1.66669 9.99996C1.66669 14.5916 5.40835 18.3333 10 18.3333C14.5917 18.3333 18.3334 14.5916 18.3334 9.99996C18.3334 5.40829 14.5917 1.66663 10 1.66663ZM13.9834 8.08329L9.25835 12.8083C9.14169 12.925 8.98335 12.9916 8.81669 12.9916C8.65002 12.9916 8.49169 12.925 8.37502 12.8083L6.01669 10.45C5.77502 10.2083 5.77502 9.80829 6.01669 9.56663C6.25835 9.32496 6.65835 9.32496 6.90002 9.56663L8.81669 11.4833L13.1 7.19996C13.3417 6.95829 13.7417 6.95829 13.9834 7.19996C14.225 7.44163 14.225 7.83329 13.9834 8.08329Z"
        fill="#19A594"
      />
    </svg>
  );
};

export const VerifiedTickSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_14903_1125)">
        <path
          d="M24.7109 5.66727C25.0148 6.4023 25.5982 6.98655 26.3328 7.29157L28.9085 8.3585C29.6436 8.66298 30.2277 9.247 30.5321 9.98208C30.8366 10.7172 30.8366 11.5431 30.5321 12.2782L29.466 14.8521C29.1614 15.5875 29.1609 16.4143 29.4669 17.1493L30.5312 19.7225C30.6822 20.0866 30.7599 20.4768 30.76 20.871C30.76 21.2651 30.6824 21.6554 30.5316 22.0195C30.3808 22.3836 30.1597 22.7145 29.881 22.9931C29.6022 23.2718 29.2713 23.4927 28.9071 23.6435L26.3332 24.7096C25.5982 25.0135 25.0139 25.5969 24.7089 26.3315L23.642 28.9073C23.3375 29.6423 22.7535 30.2264 22.0184 30.5308C21.2833 30.8353 20.4574 30.8353 19.7223 30.5308L17.1484 29.4647C16.4133 29.161 15.5876 29.1616 14.853 29.4664L12.2772 30.5318C11.5425 30.8356 10.7173 30.8353 9.98283 30.5311C9.24834 30.2269 8.66464 29.6435 8.35995 28.9092L7.29271 26.3327C6.98879 25.5977 6.40542 25.0134 5.67085 24.7084L3.09507 23.6415C2.36031 23.3371 1.77646 22.7535 1.47187 22.0188C1.16727 21.2841 1.16686 20.4586 1.47072 19.7236L2.53688 17.1497C2.84061 16.4146 2.83999 15.5889 2.53515 14.8543L1.47053 12.2767C1.31958 11.9126 1.24186 11.5223 1.2418 11.1282C1.24173 10.7341 1.31933 10.3438 1.47016 9.97969C1.62098 9.61556 1.84208 9.28472 2.12082 9.00608C2.39955 8.72743 2.73046 8.50644 3.09463 8.35573L5.66856 7.28957C6.40295 6.98592 6.98685 6.40328 7.2921 5.66956L8.35902 3.09378C8.6635 2.3587 9.24753 1.77468 9.98261 1.47019C10.7177 1.16571 11.5436 1.16571 12.2787 1.47019L14.8526 2.53635C15.5877 2.84008 16.4134 2.83946 17.148 2.53462L19.7249 1.47185C20.4599 1.16754 21.2856 1.1676 22.0206 1.47202C22.7555 1.77644 23.3395 2.3603 23.644 3.09519L24.7112 5.67173L24.7109 5.66727Z"
          fill="#19A594"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.2665 11.8058C22.4803 11.4701 22.5519 11.0631 22.4656 10.6746C22.3794 10.286 22.1423 9.94759 21.8065 9.73383C21.4708 9.52006 21.0638 9.44843 20.6753 9.5347C20.2867 9.62097 19.9483 9.85806 19.7345 10.1938L13.8605 19.4238L11.1725 16.0638C10.924 15.753 10.5622 15.5536 10.1667 15.5095C9.77118 15.4655 9.37436 15.5803 9.06352 15.8288C8.75269 16.0773 8.55331 16.4391 8.50923 16.8347C8.46516 17.2302 8.58001 17.627 8.82852 17.9378L12.8285 22.9378C12.9776 23.1244 13.1691 23.2727 13.387 23.3703C13.6049 23.468 13.843 23.5122 14.0815 23.4993C14.32 23.4864 14.5519 23.4167 14.758 23.2961C14.9641 23.1755 15.1385 23.0074 15.2665 22.8058L22.2665 11.8058Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_14903_1125">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const VenturousSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="28"
      viewBox="0 0 35 28"
      fill="none"
      {...props}
    >
      <path
        d="M17.1989 12.4996L14.2627 17.6206L3.18036 0.00317383H9.27969L17.1989 12.4996Z"
        fill="#ED5C38"
      />
      <path
        d="M17.1991 12.4992L14.1698 17.4714L3.18049 0L0 5.53088L13.786 27.9997L20.5366 17.1485L17.1991 12.4992Z"
        fill="#19A594"
      />
      <path
        d="M20.5366 17.1487L13.786 27.9999H20.4348L26.9964 17.3059L20.5366 17.1487Z"
        fill="#003C32"
      />
      <path
        d="M17.1992 12.4993L20.5367 17.1486L26.9965 17.3058L23.2404 12.4993H17.1992Z"
        fill="#ED5C38"
      />
      <path
        d="M23.2404 12.4991L26.9965 17.3056L34.25 5.48303L27.8134 5.01416L23.2404 12.4991Z"
        fill="#FEC658"
      />
      <path
        d="M17.1992 12.4996H23.2404L27.8135 5.01464L24.3655 0.00317383L17.1992 12.4996Z"
        fill="#FA9749"
      />
      <path
        d="M24.3651 0.00317383L27.8131 5.01464L34.2496 5.48352L30.5342 0.51415L24.3651 0.00317383Z"
        fill="#ED5C38"
      />
    </svg>
  );
};

export const Edit2SVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    fill?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.stroke ?? "#292D32"}
      {...props}
    >
      <path d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z" />
      <path d="M17.3399 10.9508C17.3199 10.9508 17.2899 10.9508 17.2699 10.9508C14.1499 10.6408 11.6399 8.27083 11.1599 5.17083C11.0999 4.76083 11.3799 4.38083 11.7899 4.31083C12.1999 4.25083 12.5799 4.53083 12.6499 4.94083C13.0299 7.36083 14.9899 9.22083 17.4299 9.46083C17.8399 9.50083 18.1399 9.87083 18.0999 10.2808C18.0499 10.6608 17.7199 10.9508 17.3399 10.9508Z" />
      <path d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z" />
    </svg>
  );
};

export const ArrowLeftSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    fill?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M9.56994 18.8191C9.37994 18.8191 9.18994 18.7491 9.03994 18.5991L2.96994 12.5291C2.67994 12.2391 2.67994 11.7591 2.96994 11.4691L9.03994 5.39914C9.32994 5.10914 9.80994 5.10914 10.0999 5.39914C10.3899 5.68914 10.3899 6.16914 10.0999 6.45914L4.55994 11.9991L10.0999 17.5391C10.3899 17.8291 10.3899 18.3091 10.0999 18.5991C9.95994 18.7491 9.75994 18.8191 9.56994 18.8191Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M20.5 12.75H3.67004C3.26004 12.75 2.92004 12.41 2.92004 12C2.92004 11.59 3.26004 11.25 3.67004 11.25H20.5C20.91 11.25 21.25 11.59 21.25 12C21.25 12.41 20.91 12.75 20.5 12.75Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const ArrowRightSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    fill?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M14.43 18.8191C14.24 18.8191 14.05 18.7491 13.9 18.5991C13.61 18.3091 13.61 17.8291 13.9 17.5391L19.44 11.9991L13.9 6.45914C13.61 6.16914 13.61 5.68914 13.9 5.39914C14.19 5.10914 14.67 5.10914 14.96 5.39914L21.03 11.4691C21.32 11.7591 21.32 12.2391 21.03 12.5291L14.96 18.5991C14.81 18.7491 14.62 18.8191 14.43 18.8191Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M20.33 12.75H3.5C3.09 12.75 2.75 12.41 2.75 12C2.75 11.59 3.09 11.25 3.5 11.25H20.33C20.74 11.25 21.08 11.59 21.08 12C21.08 12.41 20.74 12.75 20.33 12.75Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const MoneyReceiveSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    fill?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M13.0505 16.2502H11.1705C9.8405 16.2502 8.75049 15.1302 8.75049 13.7502C8.75049 13.3402 9.09049 13.0002 9.50049 13.0002C9.91049 13.0002 10.2505 13.3402 10.2505 13.7502C10.2505 14.3002 10.6605 14.7502 11.1705 14.7502H13.0505C13.4405 14.7502 13.7505 14.4002 13.7505 13.9702C13.7505 13.4302 13.6005 13.3502 13.2605 13.2302L10.2505 12.1802C9.61049 11.9602 8.75049 11.4902 8.75049 10.0202C8.75049 8.77023 9.7405 7.74023 10.9505 7.74023H12.8305C14.1605 7.74023 15.2505 8.86023 15.2505 10.2402C15.2505 10.6502 14.9105 10.9902 14.5005 10.9902C14.0905 10.9902 13.7505 10.6502 13.7505 10.2402C13.7505 9.69023 13.3405 9.24023 12.8305 9.24023H10.9505C10.5605 9.24023 10.2505 9.59023 10.2505 10.0202C10.2505 10.5602 10.4005 10.6402 10.7405 10.7602L13.7505 11.8102C14.3905 12.0302 15.2505 12.5002 15.2505 13.9702C15.2505 15.2302 14.2605 16.2502 13.0505 16.2502Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M12.0005 17.25C11.5905 17.25 11.2505 16.91 11.2505 16.5V7.5C11.2505 7.09 11.5905 6.75 12.0005 6.75C12.4105 6.75 12.7505 7.09 12.7505 7.5V16.5C12.7505 16.91 12.4105 17.25 12.0005 17.25Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M12.0005 22.75C6.07049 22.75 1.25049 17.93 1.25049 12C1.25049 6.07 6.07049 1.25 12.0005 1.25C12.4105 1.25 12.7505 1.59 12.7505 2C12.7505 2.41 12.4105 2.75 12.0005 2.75C6.90049 2.75 2.75049 6.9 2.75049 12C2.75049 17.1 6.90049 21.25 12.0005 21.25C17.1005 21.25 21.2505 17.1 21.2505 12C21.2505 11.59 21.5905 11.25 22.0005 11.25C22.4105 11.25 22.7505 11.59 22.7505 12C22.7505 17.93 17.9305 22.75 12.0005 22.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M21.0005 7.75H17.0005C16.5905 7.75 16.2505 7.41 16.2505 7V3C16.2505 2.59 16.5905 2.25 17.0005 2.25C17.4105 2.25 17.7505 2.59 17.7505 3V6.25H21.0005C21.4105 6.25 21.7505 6.59 21.7505 7C21.7505 7.41 21.4105 7.75 21.0005 7.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M17.0004 7.74945C16.8104 7.74945 16.6204 7.67945 16.4704 7.52945C16.1804 7.23945 16.1804 6.75945 16.4704 6.46945L21.4704 1.46945C21.7604 1.17945 22.2404 1.17945 22.5304 1.46945C22.8204 1.75945 22.8204 2.23945 22.5304 2.52945L17.5304 7.52945C17.3804 7.67945 17.1904 7.74945 17.0004 7.74945Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const DollarSquareSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    fill?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M8.67236 14.3298C8.67236 15.6198 9.66236 16.6598 10.8924 16.6598H13.4024C14.4724 16.6598 15.3424 15.7498 15.3424 14.6298C15.3424 13.4098 14.8124 12.9798 14.0224 12.6998L9.99236 11.2998C9.20236 11.0198 8.67236 10.5898 8.67236 9.36984C8.67236 8.24984 9.54236 7.33984 10.6124 7.33984H13.1224C14.3524 7.33984 15.3424 8.37984 15.3424 9.66984"
        stroke={props.stroke ?? "#292D32"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0005 6V18"
        stroke={props.stroke ?? "#292D32"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0005 22H9.00049C4.00049 22 2.00049 20 2.00049 15V9C2.00049 4 4.00049 2 9.00049 2H15.0005C20.0005 2 22.0005 4 22.0005 9V15C22.0005 20 20.0005 22 15.0005 22Z"
        stroke={props.stroke ?? "#292D32"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReceiptEditSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M20.5 11.3V7.04001C20.5 3.01001 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01001 3.5 7.04001V18.3C3.5 20.96 4.96001 21.59 6.73001 19.69L6.73999 19.68C7.55999 18.81 8.80999 18.88 9.51999 19.83L10.53 21.18"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 7H16"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11H15"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.211 14.7703L14.671 18.3103C14.531 18.4503 14.401 18.7103 14.371 18.9003L14.181 20.2503C14.111 20.7403 14.451 21.0803 14.941 21.0103L16.291 20.8203C16.481 20.7903 16.751 20.6603 16.881 20.5203L20.421 16.9803C21.031 16.3703 21.321 15.6603 20.421 14.7603C19.531 13.8703 18.821 14.1603 18.211 14.7703Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6992 15.2803C17.9992 16.3603 18.8392 17.2003 19.9192 17.5003"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ClockSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7099 15.18L12.6099 13.33C12.0699 13.01 11.6299 12.24 11.6299 11.61V7.51001"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CalendarAddSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8 2V5"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2V5"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 9.09H20.5"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C15.7909 15 14 16.7909 14 19C14 21.2091 15.7909 23 18 23Z"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4898 19.05H16.5098"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 17.59V20.58"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 8.5V16.36C20.27 15.53 19.2 15 18 15C15.79 15 14 16.79 14 19C14 19.75 14.21 20.46 14.58 21.06C14.79 21.42 15.06 21.74 15.37 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9955 13.7H12.0045"
        stroke="#22234A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.29431 13.7H8.30329"
        stroke="#22234A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.29431 16.7H8.30329"
        stroke="#22234A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CalendarSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8 2V5"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2V5"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.75 17.6H3.25"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.25C10.77 8.25 9.73 8.92 9.73 10.22C9.73 10.84 10.02 11.31 10.46 11.61C9.85 11.97 9.5 12.55 9.5 13.23C9.5 14.47 10.45 15.24 12 15.24C13.54 15.24 14.5 14.47 14.5 13.23C14.5 12.55 14.15 11.96 13.53 11.61C13.98 11.3 14.26 10.84 14.26 10.22C14.26 8.92 13.23 8.25 12 8.25ZM12 11.09C11.48 11.09 11.1 10.78 11.1 10.29C11.1 9.79 11.48 9.5 12 9.5C12.52 9.5 12.9 9.79 12.9 10.29C12.9 10.78 12.52 11.09 12 11.09ZM12 14C11.34 14 10.86 13.67 10.86 13.07C10.86 12.47 11.34 12.15 12 12.15C12.66 12.15 13.14 12.48 13.14 13.07C13.14 13.67 12.66 14 12 14Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const UserTickSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.41016 22C3.41016 18.13 7.26015 15 12.0002 15C12.9602 15 13.8902 15.13 14.7602 15.37"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 18C22 18.75 21.79 19.46 21.42 20.06C21.21 20.42 20.94 20.74 20.63 21C19.93 21.63 19.01 22 18 22C16.54 22 15.27 21.22 14.58 20.06C14.21 19.46 14 18.75 14 18C14 16.74 14.58 15.61 15.5 14.88C16.19 14.33 17.06 14 18 14C20.21 14 22 15.79 22 18Z"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4404 18L17.4304 18.99L19.5604 17.02"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DollarCircleSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6V18"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MonitorMobileSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.4917 8.09163C17.2083 7.97496 16.8333 7.91663 16.3667 7.91663H11.9667C10.5167 7.91663 10 8.43329 10 9.89996V16.35C10 16.8333 10.0583 17.2083 10.1833 17.5C10.4417 18.1 10.9917 18.3333 11.9667 18.3333H16.3667C17.8167 18.3333 18.3333 17.8083 18.3333 16.35V9.89996C18.3333 8.90829 18.1 8.34996 17.4917 8.09163ZM15 16.4583H13.3333C13.3167 16.4583 13.2917 16.4583 13.275 16.45C13.15 16.4416 13.0417 16.4 12.95 16.3166C12.8 16.2083 12.7083 16.0333 12.7083 15.8333C12.7083 15.4916 12.9917 15.2083 13.3333 15.2083H15C15.3417 15.2083 15.625 15.4916 15.625 15.8333C15.625 16.175 15.3417 16.4583 15 16.4583Z"
        fill="#292D32"
      />
      <path
        d="M17.4915 5.15841V5.83342C17.4915 6.29175 17.1165 6.66675 16.6581 6.66675H11.9665C9.83314 6.66675 8.7498 7.75842 8.7498 9.90008V17.5001C8.7498 17.9584 8.3748 18.3334 7.91647 18.3334H6.29147C5.95814 18.3334 5.69147 18.0667 5.69147 17.7417C5.69147 17.4084 5.95814 17.1501 6.29147 17.1501H7.91647V13.9584H4.9998C3.1498 13.8667 1.6748 12.3417 1.6748 10.4667V5.15841C1.6748 3.23341 3.24147 1.66675 5.1748 1.66675H13.9998C15.9248 1.66675 17.4915 3.23341 17.4915 5.15841Z"
        fill="#22234A"
      />
    </svg>
  );
};

export const ExportSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.833 9.16665L17.6663 2.33331"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.333 5.66669V1.66669H14.333"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16699 1.66669H7.50033C3.33366 1.66669 1.66699 3.33335 1.66699 7.50002V12.5C1.66699 16.6667 3.33366 18.3334 7.50033 18.3334H12.5003C16.667 18.3334 18.3337 16.6667 18.3337 12.5V10.8334"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ExportMissingSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M11.833 8.16665L18.6663 1.33331"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6663 8.16665L11.833 1.33331"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16699 1.66669H7.50033C3.33366 1.66669 1.66699 3.33335 1.66699 7.50002V12.5C1.66699 16.6667 3.33366 18.3334 7.50033 18.3334H12.5003C16.667 18.3334 18.3337 16.6667 18.3337 12.5V10.8334"
        stroke={props.stroke ?? "#727E94"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AddSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      {...props}
    >
      <path
        d="M4.5 8.5H12.5"
        stroke={props.stroke ?? "#22234A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 12.5V4.5"
        stroke={props.stroke ?? "#22234A"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CloseCircleSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.99935 18.3334C14.5827 18.3334 18.3327 14.5834 18.3327 10C18.3327 5.41669 14.5827 1.66669 9.99935 1.66669C5.41602 1.66669 1.66602 5.41669 1.66602 10C1.66602 14.5834 5.41602 18.3334 9.99935 18.3334Z"
        stroke="#8D98A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.6416 12.3576L12.3576 7.64166"
        stroke="#8D98A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3576 12.3576L7.6416 7.64166"
        stroke="#8D98A9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PeopleSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.9998 7.90977C17.9698 7.90977 17.9498 7.90977 17.9198 7.90977H17.8698C15.9798 7.84977 14.5698 6.38977 14.5698 4.58977C14.5698 2.74977 16.0698 1.25977 17.8998 1.25977C19.7298 1.25977 21.2298 2.75977 21.2298 4.58977C21.2198 6.39977 19.8098 7.85977 18.0098 7.91977C18.0098 7.90977 18.0098 7.90977 17.9998 7.90977ZM17.8998 2.74977C16.8898 2.74977 16.0698 3.56977 16.0698 4.57977C16.0698 5.56977 16.8398 6.36977 17.8298 6.40977C17.8398 6.39977 17.9198 6.39977 18.0098 6.40977C18.9798 6.35977 19.7298 5.55977 19.7398 4.57977C19.7398 3.56977 18.9198 2.74977 17.8998 2.74977Z"
        fill="#292D32"
      />
      <path
        d="M18.01 15.2806C17.62 15.2806 17.23 15.2506 16.84 15.1806C16.43 15.1106 16.16 14.7206 16.23 14.3106C16.3 13.9006 16.69 13.6306 17.1 13.7006C18.33 13.9106 19.63 13.6806 20.5 13.1006C20.97 12.7906 21.22 12.4006 21.22 12.0106C21.22 11.6206 20.96 11.2406 20.5 10.9306C19.63 10.3506 18.31 10.1206 17.07 10.3406C16.66 10.4206 16.27 10.1406 16.2 9.73063C16.13 9.32063 16.4 8.93064 16.81 8.86064C18.44 8.57064 20.13 8.88063 21.33 9.68063C22.21 10.2706 22.72 11.1106 22.72 12.0106C22.72 12.9006 22.22 13.7506 21.33 14.3506C20.42 14.9506 19.24 15.2806 18.01 15.2806Z"
        fill="#292D32"
      />
      <path
        d="M5.96998 7.91C5.95998 7.91 5.94998 7.91 5.94998 7.91C4.14998 7.85 2.73998 6.39 2.72998 4.59C2.72998 2.75 4.22998 1.25 6.05998 1.25C7.88998 1.25 9.38998 2.75 9.38998 4.58C9.38998 6.39 7.97998 7.85 6.17998 7.91L5.96998 7.16L6.03998 7.91C6.01998 7.91 5.98998 7.91 5.96998 7.91ZM6.06998 6.41C6.12998 6.41 6.17998 6.41 6.23998 6.42C7.12998 6.38 7.90998 5.58 7.90998 4.59C7.90998 3.58 7.08998 2.75999 6.07998 2.75999C5.06998 2.75999 4.24998 3.58 4.24998 4.59C4.24998 5.57 5.00998 6.36 5.97998 6.42C5.98998 6.41 6.02998 6.41 6.06998 6.41Z"
        fill="#292D32"
      />
      <path
        d="M5.96 15.2806C4.73 15.2806 3.55 14.9506 2.64 14.3506C1.76 13.7606 1.25 12.9106 1.25 12.0106C1.25 11.1206 1.76 10.2706 2.64 9.68063C3.84 8.88063 5.53 8.57064 7.16 8.86064C7.57 8.93064 7.84 9.32063 7.77 9.73063C7.7 10.1406 7.31 10.4206 6.9 10.3406C5.66 10.1206 4.35 10.3506 3.47 10.9306C3 11.2406 2.75 11.6206 2.75 12.0106C2.75 12.4006 3.01 12.7906 3.47 13.1006C4.34 13.6806 5.64 13.9106 6.87 13.7006C7.28 13.6306 7.67 13.9106 7.74 14.3106C7.81 14.7206 7.54 15.1106 7.13 15.1806C6.74 15.2506 6.35 15.2806 5.96 15.2806Z"
        fill="#292D32"
      />
      <path
        d="M11.9998 15.3805C11.9698 15.3805 11.9498 15.3805 11.9198 15.3805H11.8698C9.97982 15.3205 8.56982 13.8605 8.56982 12.0605C8.56982 10.2205 10.0698 8.73047 11.8998 8.73047C13.7298 8.73047 15.2298 10.2305 15.2298 12.0605C15.2198 13.8705 13.8098 15.3305 12.0098 15.3905C12.0098 15.3805 12.0098 15.3805 11.9998 15.3805ZM11.8998 10.2205C10.8898 10.2205 10.0698 11.0405 10.0698 12.0505C10.0698 13.0405 10.8398 13.8405 11.8298 13.8805C11.8398 13.8705 11.9198 13.8705 12.0098 13.8805C12.9798 13.8305 13.7298 13.0305 13.7398 12.0505C13.7398 11.0505 12.9198 10.2205 11.8998 10.2205Z"
        fill="#292D32"
      />
      <path
        d="M11.9998 22.7607C10.7998 22.7607 9.59978 22.4507 8.66978 21.8207C7.78978 21.2307 7.27979 20.3907 7.27979 19.4907C7.27979 18.6007 7.77978 17.7407 8.66978 17.1507C10.5398 15.9107 13.4698 15.9107 15.3298 17.1507C16.2098 17.7407 16.7198 18.5807 16.7198 19.4807C16.7198 20.3707 16.2198 21.2307 15.3298 21.8207C14.3998 22.4407 13.1998 22.7607 11.9998 22.7607ZM9.49979 18.4107C9.02979 18.7207 8.77979 19.1107 8.77979 19.5007C8.77979 19.8907 9.03979 20.2707 9.49979 20.5807C10.8498 21.4907 13.1398 21.4907 14.4898 20.5807C14.9598 20.2707 15.2098 19.8807 15.2098 19.4907C15.2098 19.1007 14.9498 18.7207 14.4898 18.4107C13.1498 17.5007 10.8598 17.5107 9.49979 18.4107Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const UserSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22"
        stroke="#22234A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const DraggableSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    stroke?: string;
    fill?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  );
};

export const LogoutSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M15.24 22.2705H15.11C10.67 22.2705 8.53002 20.5205 8.16002 16.6005C8.12002 16.1905 8.42002 15.8205 8.84002 15.7805C9.24002 15.7405 9.62002 16.0505 9.66002 16.4605C9.95002 19.6005 11.43 20.7705 15.12 20.7705H15.25C19.32 20.7705 20.76 19.3305 20.76 15.2605V8.74047C20.76 4.67047 19.32 3.23047 15.25 3.23047H15.12C11.41 3.23047 9.93002 4.42047 9.66002 7.62047C9.61002 8.03047 9.26002 8.34047 8.84002 8.30047C8.42002 8.27047 8.12001 7.90047 8.15001 7.49047C8.49001 3.51047 10.64 1.73047 15.11 1.73047H15.24C20.15 1.73047 22.25 3.83047 22.25 8.74047V15.2605C22.25 20.1705 20.15 22.2705 15.24 22.2705Z"
        fill="#292D32"
      />
      <path
        d="M15 12.75H3.62C3.21 12.75 2.87 12.41 2.87 12C2.87 11.59 3.21 11.25 3.62 11.25H15C15.41 11.25 15.75 11.59 15.75 12C15.75 12.41 15.41 12.75 15 12.75Z"
        fill="#292D32"
      />
      <path
        d="M5.84994 16.0998C5.65994 16.0998 5.46994 16.0298 5.31994 15.8798L1.96994 12.5298C1.67994 12.2398 1.67994 11.7598 1.96994 11.4698L5.31994 8.11984C5.60994 7.82984 6.08994 7.82984 6.37994 8.11984C6.66994 8.40984 6.66994 8.88984 6.37994 9.17984L3.55994 11.9998L6.37994 14.8198C6.66994 15.1098 6.66994 15.5898 6.37994 15.8798C6.23994 16.0298 6.03994 16.0998 5.84994 16.0998Z"
        fill="#292D32"
      />
    </svg>
  );
};

export const StarSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M13.7299 3.51063L15.4899 7.03063C15.7299 7.52063 16.3699 7.99063 16.9099 8.08063L20.0999 8.61062C22.1399 8.95062 22.6199 10.4306 21.1499 11.8906L18.6699 14.3706C18.2499 14.7906 18.0199 15.6006 18.1499 16.1806L18.8599 19.2506C19.4199 21.6806 18.1299 22.6206 15.9799 21.3506L12.9899 19.5806C12.4499 19.2606 11.5599 19.2606 11.0099 19.5806L8.01991 21.3506C5.87991 22.6206 4.57991 21.6706 5.13991 19.2506L5.84991 16.1806C5.97991 15.6006 5.74991 14.7906 5.32991 14.3706L2.84991 11.8906C1.38991 10.4306 1.85991 8.95062 3.89991 8.61062L7.08991 8.08063C7.61991 7.99063 8.25991 7.52063 8.49991 7.03063L10.2599 3.51063C11.2199 1.60063 12.7799 1.60063 13.7299 3.51063Z"
        stroke={props.stroke ?? "#292D32"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const TaskSquareSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M17.6201 9.61914H12.3701C11.9601 9.61914 11.6201 9.27914 11.6201 8.86914C11.6201 8.45914 11.9601 8.11914 12.3701 8.11914H17.6201C18.0301 8.11914 18.3701 8.45914 18.3701 8.86914C18.3701 9.27914 18.0401 9.61914 17.6201 9.61914Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M7.12006 10.3803C6.93006 10.3803 6.74006 10.3103 6.59006 10.1603L5.84006 9.41031C5.55006 9.12031 5.55006 8.64031 5.84006 8.35031C6.13006 8.06031 6.61006 8.06031 6.90006 8.35031L7.12006 8.57031L8.84006 6.85031C9.13006 6.56031 9.61006 6.56031 9.90006 6.85031C10.1901 7.14031 10.1901 7.62031 9.90006 7.91031L7.65006 10.1603C7.51006 10.3003 7.32006 10.3803 7.12006 10.3803Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M17.6201 16.6191H12.3701C11.9601 16.6191 11.6201 16.2791 11.6201 15.8691C11.6201 15.4591 11.9601 15.1191 12.3701 15.1191H17.6201C18.0301 15.1191 18.3701 15.4591 18.3701 15.8691C18.3701 16.2791 18.0401 16.6191 17.6201 16.6191Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M7.12006 17.3803C6.93006 17.3803 6.74006 17.3103 6.59006 17.1603L5.84006 16.4103C5.55006 16.1203 5.55006 15.6403 5.84006 15.3503C6.13006 15.0603 6.61006 15.0603 6.90006 15.3503L7.12006 15.5703L8.84006 13.8503C9.13006 13.5603 9.61006 13.5603 9.90006 13.8503C10.1901 14.1403 10.1901 14.6203 9.90006 14.9103L7.65006 17.1603C7.51006 17.3003 7.32006 17.3803 7.12006 17.3803Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const Profile2UserSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M9.15957 11.62C9.12957 11.62 9.10957 11.62 9.07957 11.62C9.02957 11.61 8.95957 11.61 8.89957 11.62C5.99957 11.53 3.80957 9.25 3.80957 6.44C3.80957 3.58 6.13957 1.25 8.99957 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18957 11.62C9.17957 11.62 9.16957 11.62 9.15957 11.62ZM8.99957 2.75C6.96957 2.75 5.30957 4.41 5.30957 6.44C5.30957 8.44 6.86957 10.05 8.85957 10.12C8.91957 10.11 9.04957 10.11 9.17957 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99957 2.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M16.5396 11.75C16.5096 11.75 16.4796 11.75 16.4496 11.74C16.0396 11.78 15.6196 11.49 15.5796 11.08C15.5396 10.67 15.7896 10.3 16.1996 10.25C16.3196 10.24 16.4496 10.24 16.5596 10.24C18.0196 10.16 19.1596 8.96 19.1596 7.49C19.1596 5.97 17.9296 4.74 16.4096 4.74C15.9996 4.75 15.6596 4.41 15.6596 4C15.6596 3.59 15.9996 3.25 16.4096 3.25C18.7496 3.25 20.6596 5.16 20.6596 7.5C20.6596 9.8 18.8596 11.66 16.5696 11.75C16.5596 11.75 16.5496 11.75 16.5396 11.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M18.3397 20.75C17.9897 20.75 17.6797 20.51 17.6097 20.15C17.5297 19.74 17.7897 19.35 18.1897 19.26C18.8197 19.13 19.3997 18.88 19.8497 18.53C20.4197 18.1 20.7297 17.56 20.7297 16.99C20.7297 16.42 20.4197 15.88 19.8597 15.46C19.4197 15.12 18.8697 14.88 18.2197 14.73C17.8197 14.64 17.5597 14.24 17.6497 13.83C17.7397 13.43 18.1397 13.17 18.5497 13.26C19.4097 13.45 20.1597 13.79 20.7697 14.26C21.6997 14.96 22.2297 15.95 22.2297 16.99C22.2297 18.03 21.6897 19.02 20.7597 19.73C20.1397 20.21 19.3597 20.56 18.4997 20.73C18.4397 20.75 18.3897 20.75 18.3397 20.75Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const MaximizeSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M5.99994 18.7495C5.80994 18.7495 5.61994 18.6795 5.46994 18.5295C5.17994 18.2395 5.17994 17.7595 5.46994 17.4695L17.4699 5.46945C17.7599 5.17945 18.2399 5.17945 18.5299 5.46945C18.8199 5.75945 18.8199 6.23945 18.5299 6.52945L6.52994 18.5295C6.37994 18.6795 6.18994 18.7495 5.99994 18.7495Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M18 10.75C17.59 10.75 17.25 10.41 17.25 10V6.75H14C13.59 6.75 13.25 6.41 13.25 6C13.25 5.59 13.59 5.25 14 5.25H18C18.41 5.25 18.75 5.59 18.75 6V10C18.75 10.41 18.41 10.75 18 10.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M10 18.75H6C5.59 18.75 5.25 18.41 5.25 18V14C5.25 13.59 5.59 13.25 6 13.25C6.41 13.25 6.75 13.59 6.75 14V17.25H10C10.41 17.25 10.75 17.59 10.75 18C10.75 18.41 10.41 18.75 10 18.75Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const InfoCircleBoldSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM11.25 8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75C11.59 13.75 11.25 13.41 11.25 13V8ZM12.92 16.38C12.87 16.51 12.8 16.61 12.71 16.71C12.61 16.8 12.5 16.87 12.38 16.92C12.26 16.97 12.13 17 12 17C11.87 17 11.74 16.97 11.62 16.92C11.5 16.87 11.39 16.8 11.29 16.71C11.2 16.61 11.13 16.51 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.87 11.03 15.74 11.08 15.62C11.13 15.5 11.2 15.39 11.29 15.29C11.39 15.2 11.5 15.13 11.62 15.08C11.86 14.98 12.14 14.98 12.38 15.08C12.5 15.13 12.61 15.2 12.71 15.29C12.8 15.39 12.87 15.5 12.92 15.62C12.97 15.74 13 15.87 13 16C13 16.13 12.97 16.26 12.92 16.38Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const SmsSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M17 21.25H7C3.35 21.25 1.25 19.15 1.25 15.5V8.5C1.25 4.85 3.35 2.75 7 2.75H17C20.65 2.75 22.75 4.85 22.75 8.5V15.5C22.75 19.15 20.65 21.25 17 21.25ZM7 4.25C4.14 4.25 2.75 5.64 2.75 8.5V15.5C2.75 18.36 4.14 19.75 7 19.75H17C19.86 19.75 21.25 18.36 21.25 15.5V8.5C21.25 5.64 19.86 4.25 17 4.25H7Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M11.9998 12.87C11.1598 12.87 10.3098 12.61 9.65978 12.08L6.52978 9.57997C6.20978 9.31997 6.14978 8.84997 6.40978 8.52997C6.66978 8.20997 7.13978 8.14997 7.45978 8.40997L10.5898 10.91C11.3498 11.52 12.6398 11.52 13.3998 10.91L16.5298 8.40997C16.8498 8.14997 17.3298 8.19997 17.5798 8.52997C17.8398 8.84997 17.7898 9.32997 17.4598 9.57997L14.3298 12.08C13.6898 12.61 12.8398 12.87 11.9998 12.87Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const ClipboardTextSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M15 12.9492H8C7.59 12.9492 7.25 12.6092 7.25 12.1992C7.25 11.7892 7.59 11.4492 8 11.4492H15C15.41 11.4492 15.75 11.7892 15.75 12.1992C15.75 12.6092 15.41 12.9492 15 12.9492Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M12.38 16.9492H8C7.59 16.9492 7.25 16.6092 7.25 16.1992C7.25 15.7892 7.59 15.4492 8 15.4492H12.38C12.79 15.4492 13.13 15.7892 13.13 16.1992C13.13 16.6092 12.79 16.9492 12.38 16.9492Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M14 6.75H10C9.04 6.75 7.25 6.75 7.25 4C7.25 1.25 9.04 1.25 10 1.25H14C14.96 1.25 16.75 1.25 16.75 4C16.75 4.96 16.75 6.75 14 6.75ZM10 2.75C9.01 2.75 8.75 2.75 8.75 4C8.75 5.25 9.01 5.25 10 5.25H14C15.25 5.25 15.25 4.99 15.25 4C15.25 2.75 14.99 2.75 14 2.75H10Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M15 22.7504H9C3.38 22.7504 2.25 20.1704 2.25 16.0004V10.0004C2.25 5.44042 3.9 3.49042 7.96 3.28042C8.36 3.26042 8.73 3.57042 8.75 3.99042C8.77 4.41042 8.45 4.75042 8.04 4.77042C5.2 4.93042 3.75 5.78042 3.75 10.0004V16.0004C3.75 19.7004 4.48 21.2504 9 21.2504H15C19.52 21.2504 20.25 19.7004 20.25 16.0004V10.0004C20.25 5.78042 18.8 4.93042 15.96 4.77042C15.55 4.75042 15.23 4.39042 15.25 3.98042C15.27 3.57042 15.63 3.25042 16.04 3.27042C20.1 3.49042 21.75 5.44042 21.75 9.99042V15.9904C21.75 20.1704 20.62 22.7504 15 22.7504Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};

export const EllipseSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill={props.fill ?? "none"}
      {...props}
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill={props.fill ?? "white"}
        stroke={props.stroke ?? "#F2F2F2"}
        strokeWidth="2"
      />
    </svg>
  );
};

export const TrashSVG = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M21.0002 6.73046C20.9802 6.73046 20.9502 6.73046 20.9202 6.73046C15.6302 6.20046 10.3502 6.00046 5.12016 6.53046L3.08016 6.73046C2.66016 6.77046 2.29016 6.47046 2.25016 6.05046C2.21016 5.63046 2.51016 5.27046 2.92016 5.23046L4.96016 5.03046C10.2802 4.49046 15.6702 4.70046 21.0702 5.23046C21.4802 5.27046 21.7802 5.64046 21.7402 6.05046C21.7102 6.44046 21.3802 6.73046 21.0002 6.73046Z"
        fill={props.stroke ?? "none"}
      />
      <path
        d="M8.49977 5.72C8.45977 5.72 8.41977 5.72 8.36977 5.71C7.96977 5.64 7.68977 5.25 7.75977 4.85L7.97977 3.54C8.13977 2.58 8.35977 1.25 10.6898 1.25H13.3098C15.6498 1.25 15.8698 2.63 16.0198 3.55L16.2398 4.85C16.3098 5.26 16.0298 5.65 15.6298 5.71C15.2198 5.78 14.8298 5.5 14.7698 5.1L14.5498 3.8C14.4098 2.93 14.3798 2.76 13.3198 2.76H10.6998C9.63977 2.76 9.61977 2.9 9.46977 3.79L9.23977 5.09C9.17977 5.46 8.85977 5.72 8.49977 5.72Z"
        fill={props.stroke ?? "none"}
      />
      <path
        d="M15.2099 22.7496H8.7899C5.2999 22.7496 5.1599 20.8196 5.0499 19.2596L4.3999 9.18959C4.3699 8.77959 4.6899 8.41959 5.0999 8.38959C5.5199 8.36959 5.8699 8.67959 5.8999 9.08959L6.5499 19.1596C6.6599 20.6796 6.6999 21.2496 8.7899 21.2496H15.2099C17.3099 21.2496 17.3499 20.6796 17.4499 19.1596L18.0999 9.08959C18.1299 8.67959 18.4899 8.36959 18.8999 8.38959C19.3099 8.41959 19.6299 8.76959 19.5999 9.18959L18.9499 19.2596C18.8399 20.8196 18.6999 22.7496 15.2099 22.7496Z"
        fill={props.stroke ?? "none"}
      />
      <path
        d="M13.6601 17.25H10.3301C9.92008 17.25 9.58008 16.91 9.58008 16.5C9.58008 16.09 9.92008 15.75 10.3301 15.75H13.6601C14.0701 15.75 14.4101 16.09 14.4101 16.5C14.4101 16.91 14.0701 17.25 13.6601 17.25Z"
        fill={props.stroke ?? "none"}
      />
      <path
        d="M14.5 13.25H9.5C9.09 13.25 8.75 12.91 8.75 12.5C8.75 12.09 9.09 11.75 9.5 11.75H14.5C14.91 11.75 15.25 12.09 15.25 12.5C15.25 12.91 14.91 13.25 14.5 13.25Z"
        fill={props.stroke ?? "none"}
      />
    </svg>
  );
};

export const SendSvg = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M7.39993 6.32015L15.8899 3.49015C19.6999 2.22015 21.7699 4.30015 20.5099 8.11015L17.6799 16.6002C15.7799 22.3102 12.6599 22.3102 10.7599 16.6002L9.91993 14.0802L7.39993 13.2402C1.68993 11.3402 1.68993 8.23015 7.39993 6.32015Z"
        stroke={props.stroke ?? "#292D32"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1101 13.6505L13.6901 10.0605"
        stroke={props.stroke ?? "#292D32"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MenuSvg = (
  props: {
    className?: string;
    height?: string;
    width?: string;
    fill?: string;
    stroke?: string;
    onClick?: () => void;
  } = {},
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={props.fill ?? "none"}
      {...props}
    >
      <path
        d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M21 12.75H3C2.59 12.75 2.25 12.41 2.25 12C2.25 11.59 2.59 11.25 3 11.25H21C21.41 11.25 21.75 11.59 21.75 12C21.75 12.41 21.41 12.75 21 12.75Z"
        fill={props.stroke ?? "#292D32"}
      />
      <path
        d="M21 17.75H3C2.59 17.75 2.25 17.41 2.25 17C2.25 16.59 2.59 16.25 3 16.25H21C21.41 16.25 21.75 16.59 21.75 17C21.75 17.41 21.41 17.75 21 17.75Z"
        fill={props.stroke ?? "#292D32"}
      />
    </svg>
  );
};
