import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Link, Spinner } from "@fluentui/react-components";
import {
  claimCompanyProfile,
  getCompanyClaimStatus,
  resetPostingStatus,
} from "../redux/features/companyProfileSlice";

const ClaimProfile = () => {
  const [dataRequested, setDataRequested] = useState(false);

  const user = useAppSelector((state) => state.auth.user);
  const claim = useAppSelector((state) => state.companyProfile.companyClaim);
  const loadingStatus = useAppSelector((state) => state.companyProfile.status);
  const postingStatus = useAppSelector(
    (state) => state.companyProfile.postingStatus,
  );

  const { companyId } = useParams();
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const loading = !dataRequested || loadingStatus !== "succeeded";
  const posting = postingStatus !== "idle";

  // Request data on load or when companyId changes
  useEffect(() => {
    if (!companyId) {
      setDataRequested(false);
      return;
    }

    const controller = new AbortController();

    void dispatch(
      getCompanyClaimStatus({
        id: companyId,
        signal: controller.signal,
      }),
    );
    setDataRequested(true);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  // Handle a claim request completing
  useEffect(() => {
    if (postingStatus === "succeeded") {
      dispatch(resetPostingStatus());
    } else if (postingStatus === "failed") {
      dispatch(resetPostingStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postingStatus]);

  useEffect(() => {
    document.title = "Venturous";
  }, []);

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `/company/${companyId}/claim`,
        },
      });
    })();

  const handleSignUp = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `/company/${companyId}/claim`,
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  const onClaim = () => {
    if (posting || !companyId) {
      return;
    }

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(claimCompanyProfile({ id: companyId, token }));
    })();
  };

  if (!companyId || isLoading || loading) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center gap-8">
        <Spinner size="huge" />
        <div className="flex flex-col gap-1 items-stretch text-center">
          <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
            Fetching data
          </p>
          <p className="text-sm font-medium text-[#727E94]">
            Just a few seconds left
          </p>
        </div>
      </div>
    );
  }

  // If logged in, check if the user is associated with the company already
  // If not, check if the company is available to claim
  if (isAuthenticated) {
    return (
      <div className="flex flex-col gap-4 max-w-[800px] text-center items-center justify-center m-auto min-h-full">
        {claim?.name ? (
          <>
            <Avatar
              image={{ src: claim.logoLink }}
              name={claim.name}
              size={72}
              style={{ width: "88px", height: "88px" }}
            />
            <h2 className="text-4xl font-semibold text-[#22234A]">
              Hi {claim?.name}!
            </h2>
          </>
        ) : null}
        <h1 className="text-4xl font-semibold text-[#22234A]">
          Welcome to Venturous
        </h1>
        <p className="text-xl font-medium text-[#22234A]">
          Venturous offers a best-in-class, personalized network of healthcare
          experts for companies to engage talent faster and more
          cost-effectively than other options.
        </p>
        <br />
        {user?.companyIds.includes(companyId) ? (
          <>
            <p className="text-xl font-medium text-[#22234A]">
              You&apos;ve claimed this profile. Consider updating it or sharing
              a job opportunity that the Venturous team can help you with.
            </p>
            <div className="flex flex-col gap-4 items-center">
              <Button
                style={{ height: "50px", width: "300px", fontSize: "20px" }}
                appearance="primary"
                size="medium"
                onClick={() => navigate("/opening")}
              >
                Add an Opportunity
              </Button>
              <Button
                style={{ height: "50px", width: "300px", fontSize: "20px" }}
                appearance="secondary"
                size="medium"
                disabled={posting}
                onClick={() => navigate("/company/profile/edit")}
              >
                Update Company Profile
              </Button>
            </div>
          </>
        ) : claim?.available ? (
          <div className="flex flex-col gap-4 items-center">
            <Button
              style={{ height: "50px", width: "300px", fontSize: "20px" }}
              appearance="primary"
              size="medium"
              disabled={posting}
              onClick={onClaim}
            >
              {posting ? "Claiming..." : "Claim this profile"}
            </Button>
          </div>
        ) : (
          <p className="text-xl font-medium text-[#22234A]">
            The profile for this company has already been claimed. Please
            request an invite from a member of your organization.
          </p>
        )}
      </div>
    );
  }

  // If not logged in, prompt the user to sign up or log in
  return (
    <div className="flex flex-col gap-4 max-w-[800px] text-center items-center justify-center m-auto min-h-full">
      {claim?.name ? (
        <>
          <Avatar
            image={{ src: claim.logoLink }}
            name={claim.name}
            size={72}
            style={{ width: "88px", height: "88px" }}
          />
          <h2 className="text-4xl font-semibold text-[#22234A]">
            Hi {claim?.name}!
          </h2>
        </>
      ) : null}
      <h1 className="text-4xl font-semibold text-[#22234A]">
        Welcome to Venturous
      </h1>
      <p className="text-xl font-medium text-[#22234A]">
        Venturous offers a best-in-class, personalized network of healthcare
        experts for companies to engage talent faster and more cost-effectively
        than other options.
      </p>
      <br />
      <p className="text-xl font-medium text-[#22234A]">
        To claim your profile, sign up or log in.
      </p>
      <div className="flex flex-col gap-4 items-center">
        <Button
          style={{ height: "50px", width: "300px", fontSize: "20px" }}
          appearance="primary"
          size="medium"
          onClick={handleSignUp}
        >
          Sign Up
        </Button>
        <Link onClick={handleLogin}>Log In</Link>
      </div>
    </div>
  );
};

export default ClaimProfile;
