import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { createLightTheme, FluentProvider } from "@fluentui/react-components";
import CacheBuster from "react-cache-buster";
import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { usePostHog } from "posthog-js/react";
import packageInfo from "../package.json";
import Navigation from "./elements/Navigation";
import Footer from "./elements/Footer";
import PrivateRoute from "./utils/PrivateRoute";
import ErrorDialog from "./elements/ErrorDialog";
import Login from "./pages/Login";
import ClaimProfile from "./pages/ClaimProfile";
import JoinTeam from "./pages/JoinTeam";
import Onboarding from "./pages/Onboarding";
import CandidateProfile from "./pages/CandidateProfile";
import CandidateProfileEdit from "./pages/CandidateProfileEdit";
import CandidateOpportunityDashboard from "./pages/CandidateOpportunityDashboard";
import CompanyProfile from "./pages/CompanyProfile";
import Team from "./pages/Team";
import CompanyOpportunityDashboard from "./pages/CompanyOpportunityDashboard";
import CompanyOpportunity from "./pages/CompanyOpportunity";
import CompanyOpportunityEdit from "./pages/CompanyOpportunityEdit";
import Landing from "./pages/Landing";
import ReactAdmin from "./pages/Admin";
import Error404Page from "./pages/Error404Page";

import "./App.css";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { ReactNode, useEffect } from "react";
import { login, logout } from "./redux/features/authSlice";

const theme = {
  ...createLightTheme({
    10: "#020403",
    20: "#101C19",
    30: "#152E29",
    40: "#183C35",
    50: "#1B4A41",
    60: "#1D584D",
    70: "#1E675A",
    80: "#1E7667",
    90: "#1D8575",
    100: "#1C9582",
    110: "#1AA590",
    120: "#4CB29F",
    130: "#6EBFAE",
    140: "#8CCBBD",
    150: "#A8D8CD",
    160: "#C4E4DC",
  }),
  fontFamilyBase: `'Gilroy', 'Inter', 'Archivo', 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif`,
};

const Auth0ProviderWithNavigate = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: "http://localhost:5000",
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

const Auth0ReduxConnector = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const loggedIn = useAppSelector((state) => state.auth.isAuthenticated);
  const user = useAppSelector((state) => state.auth.user);
  const { pathname } = useLocation();
  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    void (async () => {
      if (isAuthenticated && !loggedIn) {
        // Fetch from server
        const token = await getAccessTokenSilently();
        void dispatch(login(token));
      } else if (!isAuthenticated && loggedIn) {
        // Logout
        void dispatch(logout());
      } else if (
        isAuthenticated &&
        loggedIn &&
        !user?.onboarded &&
        user?.userType.includes("Candidate") &&
        (pathname !== "/onboarding" || pathname.startsWith("/claim"))
      ) {
        // Trigger onboarding flow if user is new
        navigate("/onboarding");
      }
    })();
  }, [
    isAuthenticated,
    loggedIn,
    pathname,
    user?.onboarded,
    user?.userType,
    getAccessTokenSilently,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        emails: user.emails,
        name: user.name,
      });
    } else {
      posthog.reset();
    }
  }, [user, posthog]);

  return undefined;
};

function App() {
  const isProduction = process.env.NODE_ENV === "production";

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <FluentProvider
        theme={theme}
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          background: "transparent",
        }}
      >
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <Auth0ReduxConnector />
            <Navigation />
            <ErrorDialog />
            <div className="body">
              <Routes>
                <Route
                  path="/" // TODO: Add home page
                  element={<Login />}
                />

                <Route path="/login" element={<Login />} />

                <Route path="/claim/:companyId" element={<ClaimProfile />} />
                <Route
                  path="/company/:companyId/claim"
                  element={<ClaimProfile />}
                />

                <Route path="/company/:companyId/join" element={<JoinTeam />} />

                <Route
                  path="/landing"
                  element={
                    <PrivateRoute allowedUserTypes={["Candidate", "Company"]}>
                      <Landing />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/onboarding"
                  element={
                    <PrivateRoute allowedUserTypes={["Candidate"]}>
                      <Onboarding />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/candidate/opening/dashboard"
                  element={
                    <PrivateRoute allowedUserTypes={["Candidate"]}>
                      <CandidateOpportunityDashboard />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/opening/"
                  element={
                    <PrivateRoute allowedUserTypes={["Company"]}>
                      <CompanyOpportunityDashboard />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/opening/create"
                  element={<CompanyOpportunityEdit mode="create" />}
                />

                <Route
                  path="/opening/:openingId/"
                  element={<CompanyOpportunity />}
                />

                <Route
                  path="/opening/:openingId/edit"
                  element={<CompanyOpportunityEdit mode="update" />}
                />

                <Route
                  path="/opening/:openingId/application/:candidateStatusId"
                  element={<CandidateProfile />}
                />

                <Route
                  path="/candidate/:candidateId/edit"
                  element={
                    <PrivateRoute
                      extendAllowedUserIdsWithParamList={["candidateId"]}
                    >
                      <CandidateProfileEdit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/profile/edit"
                  element={
                    <PrivateRoute allowedUserTypes={["Candidate"]}>
                      <CandidateProfileEdit />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/team"
                  element={
                    <PrivateRoute allowedUserTypes={["Company"]}>
                      <Team />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/company/profile/edit"
                  element={
                    <PrivateRoute allowedUserTypes={["Company"]}>
                      <CompanyProfile mode="edit" />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/company/:companyId"
                  element={<CompanyProfile mode="view" />}
                />

                <Route
                  path="/company/:companyId/edit"
                  element={
                    <PrivateRoute allowedUserTypes={["Company"]}>
                      <CompanyProfile mode="edit" />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/admin/*"
                  element={
                    <PrivateRoute>
                      <ReactAdmin />
                    </PrivateRoute>
                  }
                />

                <Route path="*" element={<Error404Page />} />
              </Routes>
            </div>
            <Footer />
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </FluentProvider>
    </CacheBuster>
  );
}

export default App;
