import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { useDialogStyles } from "../fluentStyles";
import { clearError } from "../redux/features/errorSlice";
import { CloseCircleSVG } from "../assets/svgs";

const errorTypesToHeading = {
  SERVER_ERROR: "An error occured",
  GENERIC_BAD_INPUT: "An error occured",
  SPECIFIC_BAD_INPUT: "Invalid input",
  OUT_OF_CREDITS: "Out of credits",
  UNEXPECTED: "An error occured",
  UNAUTHORIZED: "Unauthorized",
};

const ErrorDialog = () => {
  const errorType = useAppSelector((state) => state.error.errorType);
  const errorMessage = useAppSelector((state) => state.error.errorMessage);
  const refreshOnClose = useAppSelector((state) => state.error.refreshOnClose);
  const dispatch = useAppDispatch();

  const dialogStyle = useDialogStyles();

  const onOpenChange = (dialogOpen: boolean) => {
    if (!dialogOpen) {
      dispatch(clearError());
      if (refreshOnClose) {
        window.location.reload();
      }
    }
  };

  return (
    <Dialog
      open={Boolean(errorType)}
      onOpenChange={(_evt, data) => onOpenChange(data.open)}
    >
      <DialogSurface style={{ width: "400px" }}>
        <DialogBody
          style={{ display: "flex", flexDirection: "column", gap: "32px" }}
        >
          <DialogTitle className={dialogStyle.editDialog}>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              {(errorType && errorTypesToHeading[errorType]) ??
                "An error occured"}
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <p className="text-base font-normal text-[#22234A]">
              {errorMessage ??
                "An unexpected error occured. It has been logged and sent to our team."}
            </p>
            {errorType &&
            ["SPECIFIC_BAD_INPUT", "OUT_OF_CREDITS", "UNAUTHORIZED"].includes(
              errorType,
            ) ? (
              <p className="text-base font-normal text-[#22234A]">
                If you believe you are seeing this message in error, please
                contact support.
              </p>
            ) : null}
          </DialogContent>
          <DialogActions>
            <div className="flex gap-2 items-center self-stretch pt-4 px-6 flex-1 border-top-line">
              <DialogTrigger disableButtonEnhancement>
                <Button className="w-full" appearance="secondary">
                  {refreshOnClose ? "Reload Page" : "Close"}
                </Button>
              </DialogTrigger>
            </div>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default ErrorDialog;
