import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getCandidateOpenings } from "../redux/features/candidateOpeningSlice";
import {
  Avatar,
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Divider,
  mergeClasses,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { useDialogStyles } from "../fluentStyles";
import { Button as InternalButton } from "../elements/Button";
import { NewCandidateOpportunityTile } from "../elements/OpportunityTile";
import {
  CloseCircleSVG,
  DollarSquareSVG,
  LinkedInSVG,
  MoneyReceiveSVG,
  MonitorMobileSVG,
  PeopleSVG,
  ReceiptEditSVG,
} from "../assets/svgs";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";
import { CandidateJobOpening } from "../types";

const CandidateOpportunityDashboard = () => {
  const [dataRequested, setDataRequested] = useState(false);

  const jobOpenings = useAppSelector(
    (state) => state.candidateOpening.openings,
  );
  const loadingStatus = useAppSelector(
    (state) => state.candidateOpening.status,
  );

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const loaded = dataRequested && loadingStatus === "succeeded";

  useEffect(() => {
    const controller = new AbortController();

    void (async () => {
      const token = await getAccessTokenSilently();
      void dispatch(
        getCandidateOpenings({
          token,
          signal: controller.signal,
        }),
      );
      setDataRequested(true);
    })();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = "Venturous Job Opportunities";
  }, []);

  const newOpenings = useMemo(
    () =>
      jobOpenings.filter(
        ({ status, candidateStatus }) =>
          status !== "Role Filled (lost)" &&
          status !== "Role Filled (won)" &&
          candidateStatus.status !== "Not Interested" &&
          candidateStatus.status !== "Rejected for Role",
      ),
    [jobOpenings],
  );

  const closedOpenings = useMemo(
    () =>
      jobOpenings.filter(
        ({ status, candidateStatus }) =>
          status === "Role Filled (lost)" ||
          status === "Role Filled (won)" ||
          candidateStatus.status === "Not Interested" ||
          candidateStatus.status === "Rejected for Role",
      ),
    [jobOpenings],
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <NewOpportunities openings={newOpenings} loaded={loaded} />
        <ClosedOpportunities openings={closedOpenings} loaded={loaded} />
      </div>
    </>
  );
};

const NewOpportunities = ({
  openings,
  loaded,
}: {
  openings: CandidateJobOpening[];
  loaded: boolean;
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold text-[#22234A]">
          New Job Opportunities
        </h2>
      </div>
      {!loaded ? (
        <NewCandidateOpportunityTile />
      ) : openings.length ? (
        openings.map((opening) => (
          <NewCandidateOpportunityTile
            key={`opening-${opening.id}`}
            opening={opening}
          />
        ))
      ) : (
        <div className="card flex-col gap-4 w-full">
          <p className="text-base font-medium text-[#727E94]">
            You have no new job opportunities at this time, please check back
            later!
          </p>
        </div>
      )}
    </div>
  );
};

const ClosedOpportunities = ({
  openings,
  loaded,
}: {
  openings: CandidateJobOpening[];
  loaded: boolean;
}) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="text-lg font-semibold text-[#22234A]">
        Closed Job Opportunities
      </h2>
      {!loaded ? (
        <ClosedOpportunityTile />
      ) : openings.length ? (
        openings.map((opening) => (
          <ClosedOpportunityTile
            key={`opening-${opening.id}`}
            opening={opening}
          />
        ))
      ) : (
        <div className="card flex-col gap-4 w-full">
          <p className="text-base font-medium text-[#727E94]">
            You have not applied for any job opportunities that have made a
            selection yet.
          </p>
        </div>
      )}
    </div>
  );
};

const ClosedOpportunityTile = ({
  opening,
}: {
  opening?: CandidateJobOpening;
}) => {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

  const dialogStyle = useDialogStyles();

  if (!opening) {
    return (
      <div className="card flex-col gap-4 w-full">
        <div className="flex gap-3 self-stretch items-center">
          <div className="flex-1 flex flex-col gap-1">
            <Skeleton className="w-40 h-8">
              <SkeletonItem size={32} className="w-full" />
            </Skeleton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card flex-col gap-4 w-full">
      <div className="flex gap-3 self-stretch items-center">
        <Avatar
          image={{ src: opening.company?.logoLink }}
          name={opening.company?.name}
          size={48}
        />
        <div className="flex-1 flex flex-col gap-1">
          <p className="text-base font-semibold text-[#22234A]">
            {opening.role}
          </p>
          <p className="text-sm font-normal text-[#727E94]">
            {opening.company?.name}
          </p>
        </div>
        <Button
          appearance="secondary"
          shape="circular"
          onClick={() => setDetailsDialogOpen(true)}
        >
          <p className="text-sm font-semibold text-[#4E4F6C]">See Details</p>
        </Button>
      </div>
      <Dialog
        open={detailsDialogOpen}
        onOpenChange={(_evt, data) => setDetailsDialogOpen(data.open)}
      >
        <DialogSurface
          className={mergeClasses(
            dialogStyle.editDialog,
            dialogStyle.wideDialog,
          )}
        >
          <DialogBody>
            <DialogTitle>
              <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
                Opportunity Details
                <DialogTrigger disableButtonEnhancement>
                  <CloseCircleSVG
                    className="cursor-pointer"
                    height="20"
                    width="20"
                  />
                </DialogTrigger>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="flex gap-3 self-stretch items-center">
                <Avatar
                  image={{ src: opening.company?.logoLink }}
                  name={opening.company?.name}
                  size={48}
                />
                <div className="flex-1 flex flex-col gap-1">
                  <p className="text-base font-semibold text-[#22234A]">
                    {opening.role}
                  </p>
                  <p className="text-sm font-normal text-[#727E94]">
                    {opening.company?.name}
                  </p>
                </div>
              </div>
              <Divider />
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h2 className="text-xl font-semibold text-[#22234A]">
                  Job Details
                </h2>
                <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                  {opening.shortDescription}
                </p>
              </div>
              <div className="flex flex-col md:flex-row gap-x-6 gap-y-2 self-stretch flex-wrap">
                <OpportunityKeyPoint title="Scope" value={opening?.scope} />
                <OpportunityKeyPoint title="Pay" value={opening?.budget} />
                <OpportunityKeyPoint
                  title="Desired Start Date"
                  value={dateStringToLocalDate(
                    opening.desiredStartDate,
                  )?.toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                />
              </div>
              <Divider />
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h2 className="text-xl font-semibold text-[#22234A]">
                  Key Responsibilities
                </h2>
                <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                  {opening.keyResponsibilities}
                </p>
              </div>
              <Divider />
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h2 className="text-xl font-semibold text-[#22234A]">
                  About Us
                </h2>
                <p className="text-sm font-medium text-[#4E4F6C] whitespace-pre-line">
                  {opening.company?.aboutUs}
                </p>
              </div>
              <div className="flex flex-col gap-2 self-stretch flex-wrap">
                <h3 className="text-base font-semibold text-[#22234A]">
                  Background
                </h3>
                <div className="p-4 flex flex-col gap-2 self-stretch border border-[#F2F2F2] rounded-xl">
                  <div className="flex flex-col self-stretch">
                    <BackgroundItem
                      icon={
                        <ReceiptEditSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Founded"
                      value={opening.company?.foundedYear}
                    />
                    <BackgroundItem
                      icon={
                        <PeopleSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Company Size"
                      value={opening.company?.companySize}
                    />
                    <BackgroundItem
                      icon={
                        <DollarSquareSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Funds Raised"
                      value={opening.company?.fundsRaised}
                    />
                    <BackgroundItem
                      icon={
                        <MoneyReceiveSVG
                          className="flex-shrink-0"
                          height="24"
                          width="24"
                        />
                      }
                      name="Last Fundraise"
                      value={opening.company?.lastFundraise}
                    />
                  </div>
                </div>
              </div>
              {
                /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
                opening.company?.linkedIn || opening.company?.websiteLink ? (
                  <div className="flex flex-col gap-2 self-stretch flex-wrap">
                    <h3 className="text-base font-semibold text-[#22234A]">
                      Company Links
                    </h3>
                    <div className="flex gap-2 self-stretch">
                      <LinkButton
                        icon={
                          <LinkedInSVG
                            className="flex-shrink-0"
                            height="20"
                            width="20"
                          />
                        }
                        text="Linkedin"
                        link={opening.company?.linkedIn}
                      />
                      <LinkButton
                        icon={
                          <MonitorMobileSVG
                            className="flex-shrink-0"
                            height="20"
                            width="20"
                          />
                        }
                        text="Website"
                        link={opening.company?.websiteLink}
                      />
                      <InternalButton
                        type="primary"
                        onClick={() =>
                          window.open(
                            `/company/${opening.company?.id}`,
                            "_blank",
                          )
                        }
                      >
                        Company Profile
                      </InternalButton>
                    </div>
                  </div>
                ) : null
                /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */
              }
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
};

const OpportunityKeyPoint = ({
  title,
  value,
}: {
  title: "Scope" | "Pay" | "Desired Start Date";
  value?: string;
}) => {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-1">
      <p className="text-sm font-medium text-[#727E94]">{title}:</p>
      <p className="text-sm font-semibold text-[#22234A]">{value}</p>
    </div>
  );
};

function BackgroundItem({
  icon,
  name,
  value,
}: {
  icon: JSX.Element;
  name: string;
  value?: string;
}) {
  if (!value) {
    return;
  }

  return (
    <div className="flex gap-3 self-stretch justify-between py-4 border-b border-[#F7F7F7] first:pt-0 last:pb-0 last:border-b-0">
      <div className="flex gap-3">
        {icon}
        <p className="text-sm font-medium text-[#22234A]">{name}</p>
      </div>
      <p className="text-sm font-medium text-[#22234A]">{value}</p>
    </div>
  );
}

function LinkButton({
  icon,
  text,
  link,
}: {
  icon: JSX.Element;
  text: string;
  link?: string;
}) {
  if (!link) {
    return;
  }

  return (
    <InternalButton
      type="secondary"
      onClick={() =>
        window.open(
          link.startsWith("http") ? link : `https://${link}`,
          "_blank",
        )
      }
    >
      {icon}
      {text}
    </InternalButton>
  );
}

export default CandidateOpportunityDashboard;
