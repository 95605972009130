import { useEffect } from "react";

const Error404Page = () => {
  useEffect(() => {
    document.title = "Venturous";
  }, []);

  return (
    <div className="flex flex-col gap-4 max-w-[800px] text-center justify-center m-auto min-h-full">
      <h1 className="text-4xl font-semibold text-[#22234A]">Page Not Found</h1>
      <p className="text-lg text-[#727E94]">
        We can&apos;t seem to find the page your looking for. Try going back to
        the previous page.
      </p>
    </div>
  );
};

export default Error404Page;
