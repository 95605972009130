import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "./features/errorSlice";
import authReducer from "./features/authSlice";
import candidateProfileReducer from "./features/candidateProfileSlice";
import candidateOpeningReducer from "./features/candidateOpeningSlice";
import companyProfileReducer from "./features/companyProfileSlice";
import companyOpeningReducer from "./features/companyOpeningSlice";

export const store = configureStore({
  reducer: {
    error: errorReducer,
    auth: authReducer,
    candidateProfile: candidateProfileReducer,
    candidateOpening: candidateOpeningReducer,
    companyProfile: companyProfileReducer,
    companyOpening: companyOpeningReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
