import {
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Dropdown,
  Option as FluentOption,
  Link,
  Skeleton,
  SkeletonItem,
  Toast,
  ToastTitle,
  ToastTrigger,
  Toaster,
  useId,
  useToastController,
} from "@fluentui/react-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  BusinessPrinciple,
  CommunicationSkill,
  IExperience,
  IReference,
  PeerDescription,
  Skills,
  getCandidateOpeningProfile,
} from "../redux/features/candidateProfileSlice";
import {
  ArrowLeftSVG,
  CloseCircleSVG,
  DocumentTextBoldSVG,
  DocumentTextSVG,
  ExportSVG,
  InfoCircleBoldSVG,
  LinkedInSVG,
  LocationSVG,
  MaximizeSVG,
  MonitorMobileSVG,
  Profile2UserSVG,
  SmsSVG,
  StarSVG,
  TaskSquareSVG,
  TickCircleSVG,
  VerifiedTickSVG,
} from "../assets/svgs";
import Reference from "../elements/Reference";
import Project from "../elements/Project";
import { SvgJsxType } from "../types";
import { useDialogStyles } from "../fluentStyles";
import Biography from "../elements/Biography";
import dateStringToLocalDate from "../utils/dateStringToLocalDate";

const CandidateProfile = () => {
  const [dataRequested, setDataRequested] = useState(false);
  const candidateStatus = useAppSelector(
    (state) => state.candidateProfile.status,
  );
  const candidateProfile = useAppSelector((state) => state.candidateProfile);
  const { candidateStatusId } = useParams();
  const dispatch = useAppDispatch();

  const loaded = dataRequested && candidateStatus === "succeeded";

  useEffect(() => {
    const controller = new AbortController();

    void dispatch(
      getCandidateOpeningProfile({
        id: candidateStatusId!,
        signal: controller.signal,
      }),
    );
    setDataRequested(true);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = candidateProfile.candidate.name ?? "Venturous";
  }, [candidateProfile]);

  const links = (
    <Links
      resumeLink={candidateProfile.candidate.resumeLink}
      linkedIn={candidateProfile.candidate.linkedIn}
      personalLink={candidateProfile.candidate.personalWebsite}
      email={candidateProfile.candidate.emails[0]}
    />
  );

  return (
    <>
      {loaded ? (
        <div className="flex flex-col gap-5">
          <Breadcrumb
            companyName={candidateProfile.company.name}
            role={candidateProfile.jobOpening.role}
          />
          <Header
            candidateName={candidateProfile.candidate.name}
            profilePictureLink={candidateProfile.candidate.profilePictureLink}
            titleAndEmployer={candidateProfile.candidate.titleAndEmployer}
            location={candidateProfile.candidate.location}
            scope={candidateProfile.candidateStatus.scope}
            budget={candidateProfile.candidateStatus.budget}
            desiredStartDate={
              candidateProfile.candidateStatus.earliestStartDate
            }
            targetScope={candidateProfile.jobOpening.scope}
            targetBudget={candidateProfile.jobOpening.budget}
            targetStartDate={candidateProfile.jobOpening.desiredStartDate}
            transitionsToFullTime={
              candidateProfile.jobOpening.convertsToFullTime === "Yes"
            }
            agreesWithScope={candidateProfile.candidateStatus.agreesWithScope}
            agreesWithPay={candidateProfile.candidateStatus.agreesWithPay}
            agreesWithStartDate={
              candidateProfile.candidateStatus.agreesWithStartDate
            }
            openToFullTime={candidateProfile.candidateStatus.openToFullTime}
          />
          <WhyCandidate
            candidateName={candidateProfile.candidate.name}
            matchCriteria1={candidateProfile.jobOpening.matchCriteria1}
            matchCriteria2={candidateProfile.jobOpening.matchCriteria2}
            matchCriteria3={candidateProfile.jobOpening.matchCriteria3}
            matchCriteria1Answer={
              candidateProfile.candidateStatus.matchCriteria1
            }
            matchCriteria2Answer={
              candidateProfile.candidateStatus.matchCriteria2
            }
            matchCriteria3Answer={
              candidateProfile.candidateStatus.matchCriteria3
            }
            matchCriteria1Quote={
              candidateProfile.candidateStatus.matchCriteria1Quote
            }
            matchCriteria2Quote={
              candidateProfile.candidateStatus.matchCriteria2Quote
            }
            matchCriteria3Quote={
              candidateProfile.candidateStatus.matchCriteria3Quote
            }
          />
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="flex-[2_2_0%] flex flex-col gap-5 items-stretch self-stretch lg:max-w-[66%]">
              <Experience
                experiences={candidateProfile.candidate.experiences}
              />
              <References
                references={candidateProfile.candidate.references}
                candidateName={candidateProfile.candidate.name}
              />
              <div className="hidden items-stretch self-stretch lg:flex">
                {links}
              </div>
              <InterviewQuestions questions={[]} />
            </div>
            <div className="flex-[1_1_0%] lg:max-w-[34%] flex flex-col gap-5 items-start self-stretch">
              {candidateProfile.candidate.biography ? (
                <Biography biography={candidateProfile.candidate.biography} />
              ) : null}
              <MapPersonalityHighlights
                personalityResults={
                  candidateProfile.candidate.personalityResults
                }
                personalityDetail={candidateProfile.candidate.personalityDetail}
                personalityRoleStrength={
                  candidateProfile.candidateStatus.personalityRoleStrength
                }
                personalityRoleStrengthDetail={
                  candidateProfile.candidateStatus.personalityRoleStrengthDetail
                }
                behaviorResults={candidateProfile.candidate.behaviorResults}
                behaviorDetail={candidateProfile.candidate.behaviorDetail}
                behaviorRoleStrength={
                  candidateProfile.candidateStatus.behaviorRoleStrength
                }
                behaviorRoleStrengthDetail={
                  candidateProfile.candidateStatus.behaviorRoleStrengthDetail
                }
                motivatorsResults={candidateProfile.candidate.motivatorsResults}
                motivatorsDetails={candidateProfile.candidate.motivatorsDetails}
                motivatorRoleStrength={
                  candidateProfile.candidateStatus.motivatorRoleStrength
                }
                motivatorRoleStrengthDetail={
                  candidateProfile.candidateStatus.motivatorRoleStrengthDetail
                }
                cultureLink={candidateProfile.candidate.cultureLink}
              />
              <CoreCompetencies
                topSkills={candidateProfile.candidate.topSkills}
                secondarySkills={candidateProfile.candidate.secondarySkills}
                additionalSkills={candidateProfile.candidate.additionalSkills}
              />
              <Questionnaires
                businessPrinciples={
                  candidateProfile.candidate.questionnaireBusinessPrinciples
                }
                peerDescription={
                  candidateProfile.candidate.questionnairePeerDescription
                }
                communicationSkills={
                  candidateProfile.candidate.questionnaireCommunicationSkills
                }
              />
              <Projects projects={candidateProfile.candidate.projects} />
              <div className="flex items-stretch self-stretch lg:hidden">
                {links}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-5">
          <Skeleton className="w-40">
            <SkeletonItem className="w-full" />
          </Skeleton>
          <Skeleton className="w-full card gap-6">
            <div className="flex flex-col lg:flex-row gap-3 w-full pb-3 justify-between items-start lg:items-center self-stretch border-bottom-line">
              <div className="flex items-center gap-4">
                <div className="flex flex-col items-start rounded-full">
                  <SkeletonItem
                    shape="circle"
                    style={{ height: "100px", width: "100px" }}
                  />
                </div>
                <div className="flex flex-col items-start gap-2">
                  <SkeletonItem style={{ height: "32px", width: "150px" }} />
                  <SkeletonItem style={{ height: "24px", width: "100px" }} />
                </div>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row pt-3 w-full items-start lg:items-center float-right self-stretch gap-4">
              <SkeletonItem style={{ height: "40px", width: "50%" }} />
              <SkeletonItem style={{ height: "40px", width: "50%" }} />
              <SkeletonItem style={{ height: "40px", width: "50%" }} />
              <SkeletonItem style={{ height: "40px", width: "50%" }} />
            </div>
          </Skeleton>
          <Skeleton className="w-full card">
            <div className="w-full flex flex-col gap-4">
              <div className="pb-4 border-bottom-line">
                <SkeletonItem style={{ height: "28px", width: "200px" }} />
              </div>
              <div className="flex flex-col lg:flex-row items-start self-stretch gap-4 lg:gap-2">
                <SkeletonItem style={{ height: "150px" }} />
                <SkeletonItem style={{ height: "150px" }} />
                <SkeletonItem style={{ height: "150px" }} />
                {/* <SkeletonItem style={{ height: "150px" }} /> */}
              </div>
            </div>
          </Skeleton>
          <div className="flex flex-col lg:flex-row gap-5">
            <div className="card lg:flex-[2_2_0%] max-h-[calc(95vh-73px)] lg:max-height-none">
              <div className="flex pb-4 gap-2 items-center self-stretch border-bottom-line">
                <SkeletonItem style={{ height: "28px", width: "100px" }} />
              </div>
              <div className="flex items-start self-stretch">
                <div className="flex flex-col w-[300px] py-4 pr-2 flex-grow lg:flex-grow-0 items-start self-stretch bg-white lg:border-right-line gap-3">
                  <SkeletonItem style={{ height: "80px", width: "100%" }} />
                  <SkeletonItem style={{ height: "80px", width: "100%" }} />
                  <SkeletonItem style={{ height: "80px", width: "100%" }} />
                </div>
                <div className="flex flex-col py-4 pl-5 items-start gap-5 flex-1 h-full w-full">
                  <SkeletonItem style={{ height: "100%", width: "100%" }} />
                </div>
              </div>
            </div>
            <div className="lg:flex-[1_1_0%] flex flex-col gap-5 items-start self-stretch">
              <div className="flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl h-[50%]">
                <SkeletonItem style={{ height: "100%", minHeight: "32px" }} />
              </div>
              <div className="flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl h-[50%]">
                <SkeletonItem style={{ height: "100%", minHeight: "32px" }} />
              </div>
              <div className="flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl h-[50%]">
                <SkeletonItem style={{ height: "100%", minHeight: "32px" }} />
              </div>
              <div className="flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl h-[50%]">
                <SkeletonItem style={{ height: "100%", minHeight: "32px" }} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Breadcrumb = ({
  companyName,
  role,
}: {
  companyName?: string;
  role?: string;
}) => {
  if (!companyName && !role) {
    return;
  }

  return (
    <div className="text-sm font-medium text-[#727E94]">
      {companyName} &gt; {role}
    </div>
  );
};

const Header = ({
  candidateName,
  profilePictureLink,
  titleAndEmployer,
  location,
  scope,
  budget,
  desiredStartDate,
  transitionsToFullTime,
  openToFullTime,
  agreesWithScope,
  agreesWithPay,
  agreesWithStartDate,
  targetScope,
  targetStartDate,
}: {
  candidateName?: string;
  profilePictureLink?: string;
  titleAndEmployer?: string;
  location?: string;
  scope?: string;
  budget?: string;
  desiredStartDate?: string;
  transitionsToFullTime?: boolean;
  openToFullTime?: boolean;
  agreesWithScope?: boolean;
  agreesWithPay?: boolean;
  agreesWithStartDate?: boolean;
  targetScope?: string;
  targetBudget?: string;
  targetStartDate?: string;
}) => {
  /* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
  const showMatches = scope || budget || desiredStartDate;
  /* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

  return (
    <div className="card gap-6">
      <div
        className={`flex flex-col lg:flex-row gap-3 w-full pb-3 justify-between items-start lg:items-center self-stretch ${showMatches ? "border-bottom-line" : ""}`}
      >
        <div className="flex items-center gap-4 w-full lg:w-auto">
          {profilePictureLink ? (
            <div className="flex flex-col items-start rounded-full relative">
              <Avatar
                image={{ src: profilePictureLink }}
                name={candidateName}
                size={96}
                style={{ width: "100px", height: "100px" }}
              />
              <VerifiedTickSVG className="absolute bottom-0 right-0" />
            </div>
          ) : null}
          <div className="flex flex-col items-start gap-1">
            <div className="flex flex-col gap-[2px] items-start">
              <p className="text-2xl font-semibold tracking-[-0.24px] text-[#22234A]">
                {candidateName}
              </p>
              <p className="text-sm font-medium text-[#727E94]">
                {titleAndEmployer}
              </p>
            </div>
            {location ? (
              <div className="flex items-center gap-1">
                <LocationSVG />
                <p className="text-base font-medium text-[#727E94]">
                  {location}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {showMatches ? (
        <div className="flex flex-col lg:flex-row pt-3 w-full items-start lg:items-center float-right self-stretch gap-4">
          <MatchQuestions
            key="match-scope"
            question="Scope"
            answer={agreesWithScope ? targetScope : scope}
            isMatch={agreesWithScope}
          />
          <MatchQuestions
            key="match-pay"
            question="Pay"
            answer={budget}
            isMatch={agreesWithPay}
          />
          <MatchQuestions
            key="match-start"
            question="First available start date"
            answer={
              agreesWithStartDate
                ? dateStringToLocalDate(targetStartDate)?.toLocaleDateString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    },
                  ) ?? "Unknown"
                : dateStringToLocalDate(desiredStartDate)?.toLocaleDateString(
                    "en-US",
                    {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    },
                  ) ?? "Unknown"
            }
            isMatch={agreesWithStartDate}
          />
          {transitionsToFullTime ? (
            <MatchQuestions
              key="match-full-time"
              question="Open to full-time?"
              answer={openToFullTime ? "Yes" : "No"}
              isMatch={openToFullTime}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const MatchQuestions = ({
  question,
  answer,
  isMatch,
}: {
  question: string;
  answer?: string;
  isMatch?: boolean;
}) => {
  if (!answer) {
    return;
  }

  return (
    <div
      className={`lg:max-w-[25%] flex flex-col items-start gap-1 flex-1 p-1 rounded-md`}
    >
      <p className="text-sm font-semibold text-[#727E94]">{question}</p>
      <div className="flex items-center gap-1">
        <p className="text-base font-medium text-[#22234A]">{answer}</p>
        {isMatch ? (
          <TickCircleSVG className="flex-shrink-0" height="16" width="16" />
        ) : (
          <InfoCircleBoldSVG
            className="flex-shrink-0 stroke-0"
            height="16"
            width="16"
            stroke="#FFD700"
          />
        )}
      </div>
    </div>
  );
};

const WhyCandidate = ({
  candidateName,
  matchCriteria1,
  matchCriteria2,
  matchCriteria3,
  matchCriteria1Answer,
  matchCriteria2Answer,
  matchCriteria3Answer,
  matchCriteria1Quote,
  matchCriteria2Quote,
  matchCriteria3Quote,
}: {
  candidateName?: string;
  matchCriteria1?: string;
  matchCriteria2?: string;
  matchCriteria3?: string;
  matchCriteria1Answer?: string;
  matchCriteria2Answer?: string;
  matchCriteria3Answer?: string;
  matchCriteria1Quote?: string;
  matchCriteria2Quote?: string;
  matchCriteria3Quote?: string;
}) => {
  const whyRef1 = useRef<HTMLInputElement>(null);
  const whyRef2 = useRef<HTMLInputElement>(null);
  const whyRef3 = useRef<HTMLInputElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const dialogStyle = useDialogStyles();

  useLayoutEffect(() => {
    function handleResize(): void {
      if (window.innerWidth > 1024) {
        let ref1Height = 0;
        let ref2Height = 0;
        let ref3Height = 0;

        if (whyRef1.current) {
          ref1Height =
            whyRef1.current.offsetHeight -
            parseFloat(getComputedStyle(whyRef1.current).paddingBottom);
        }
        if (whyRef2.current) {
          ref2Height =
            whyRef2.current.offsetHeight -
            parseFloat(getComputedStyle(whyRef2.current).paddingBottom);
        }
        if (whyRef3.current) {
          ref3Height =
            whyRef3.current.offsetHeight -
            parseFloat(getComputedStyle(whyRef3.current).paddingBottom);
        }

        const maxHeight = Math.max(ref1Height, ref2Height, ref3Height);

        if (whyRef1.current) {
          whyRef1.current.style.paddingBottom = `${maxHeight - ref1Height}px`;
        }
        if (whyRef2.current) {
          whyRef2.current.style.paddingBottom = `${maxHeight - ref2Height}px`;
        }
        if (whyRef3.current) {
          whyRef3.current.style.paddingBottom = `${maxHeight - ref3Height}px`;
        }
      } else {
        if (whyRef1.current) {
          whyRef1.current.style.paddingBottom = "0";
        }
        if (whyRef2.current) {
          whyRef2.current.style.paddingBottom = "0";
        }
        if (whyRef3.current) {
          whyRef3.current.style.paddingBottom = "0";
        }
      }
    }

    let timeout: NodeJS.Timeout | undefined;
    function resizeListener() {
      clearTimeout(timeout);
      timeout = setTimeout(handleResize, 15);
    }

    window.addEventListener("resize", resizeListener);
    handleResize();

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [whyRef1, whyRef2, whyRef3]);

  if (!matchCriteria1 && !matchCriteria2 && !matchCriteria3) {
    return;
  }

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const dialog = (
    <Dialog
      open={modalOpen}
      onOpenChange={(_evt, data) => setModalOpen(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              <div className="flex gap-2 items-center">
                <StarSVG
                  height="20"
                  width="20"
                  fill="#FA9749"
                  stroke="#FA9749"
                />
                <p className="text-lg font-semibold tracking-[-0.18px]">
                  Why {candidateName}?
                </p>
              </div>
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <div className="block px-8 pb-4 overflow-hidden">
              <Slider {...sliderSettings}>
                <WhyContainer
                  Icon={TaskSquareSVG}
                  criteria={matchCriteria1}
                  answer={matchCriteria1Answer}
                  quote={matchCriteria1Quote}
                />
                <WhyContainer
                  Icon={Profile2UserSVG}
                  criteria={matchCriteria2}
                  answer={matchCriteria2Answer}
                  quote={matchCriteria2Quote}
                />
                <WhyContainer
                  Icon={DocumentTextSVG}
                  criteria={matchCriteria3}
                  answer={matchCriteria3Answer}
                  quote={matchCriteria3Quote}
                />
              </Slider>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  return (
    <div className="card">
      <div className="w-full flex flex-col gap-4">
        <div className="flex items-center justify-between pb-4 border-bottom-line">
          <div className="flex gap-2 items-center">
            <StarSVG height="20" width="20" fill="#FA9749" stroke="#FA9749" />
            <p className="text-lg font-semibold tracking-[-0.18px]">
              Why {candidateName}?
            </p>
          </div>
          <div
            className="cursor-pointer p-[2px] rounded-md hover:bg-[#d8d8d8]"
            onClick={() => setModalOpen(true)}
          >
            <MaximizeSVG />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start self-stretch gap-4 lg:gap-2">
          <WhyContainer
            Icon={TaskSquareSVG}
            criteria={matchCriteria1}
            answer={matchCriteria1Answer}
            quote={matchCriteria1Quote}
            ref={whyRef1}
          />
          <WhyContainer
            Icon={Profile2UserSVG}
            criteria={matchCriteria2}
            answer={matchCriteria2Answer}
            quote={matchCriteria2Quote}
            ref={whyRef2}
          />
          <WhyContainer
            Icon={DocumentTextSVG}
            criteria={matchCriteria3}
            answer={matchCriteria3Answer}
            quote={matchCriteria3Quote}
            ref={whyRef3}
          />
        </div>
      </div>
      {dialog}
    </div>
  );
};

interface IWhyContainer {
  Icon: SvgJsxType;
  criteria?: string;
  answer?: string;
  quote?: string;
  modalSize?: boolean;
}

const WhyContainer = forwardRef<HTMLInputElement, IWhyContainer>(
  ({ Icon, criteria, answer, quote, modalSize }, ref) => {
    if (!criteria || !answer) {
      return;
    }

    return (
      <div className="flex flex-col p-3 gap-3 flex-1 items-start self-stretch rounded-xl bg-[#ED5C380D]">
        <div
          ref={ref}
          className="flex flex-col gap-[2px] flex-start self-stretch"
        >
          <div className="flex flex-row gap-2 items-center">
            <Icon
              className="flex-shrink-0 stroke-[0.2]"
              height="20"
              width="20"
              stroke="#ED5C38"
            />
            <p
              className={`${modalSize ? "text-lg" : "text-base"} font-semibold text-[#22234A] whitespace-break-space`}
            >
              {criteria}
            </p>
          </div>
          <p
            className={`${modalSize ? "text-base" : "text-sm"} font-medium text-[#4E4F6C] whitespace-break-spaces`}
          >
            {answer}
          </p>
        </div>
        {quote ? (
          <div className="flex flex-col gap-2 p-3 flex-1 items-start self-stretch rounded-xl bg-white">
            <p
              className={`${modalSize ? "text-sm" : "text-xs"} font-medium text-[#4E4F6C]`}
            >
              From the Candidate
            </p>
            <p
              className={`${modalSize ? "text-base" : "text-sm"} font-medium text-[#4E4F6C]`}
            >
              &quot;{quote}&quot;
            </p>
          </div>
        ) : null}
      </div>
    );
  },
);
WhyContainer.displayName = "WhyContainer";

const Experience = ({ experiences }: { experiences: IExperience[] }) => {
  const [selectedExperienceId, setSelectedExperienceId] = useState("");
  const [hideSelected, setHideSelected] = useState(false);
  const [experienceFilter, setExperienceFilter] = useState("All");
  const selectedExperience = experiences.find(
    ({ id }) => id === selectedExperienceId,
  );

  const filteredExperiences =
    experienceFilter === "All"
      ? experiences
      : experiences.filter(
          ({ engagementType }) => engagementType === experienceFilter,
        );

  useEffect(() => {
    // If the selected experience is not one in the filtered list
    // Either because the filter has changed or no selection was made
    // Then set the first experience as the selected one
    if (!filteredExperiences.some(({ id }) => id === selectedExperienceId)) {
      setSelectedExperienceId(filteredExperiences[0]?.id ?? "");
      setHideSelected(true);
    }
  }, [experiences, filteredExperiences, selectedExperienceId]);

  if (!experiences.length) {
    return;
  }

  const optionsSet: Set<string> = new Set<string>(["All"]);
  experiences.forEach(
    ({ engagementType }) => engagementType && optionsSet.add(engagementType),
  );

  return (
    <div className="card max-h-[calc(95vh-73px)] overflow-hidden">
      <div className="flex pb-4 gap-2 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px]">Experiences</p>
        {optionsSet.size > 2 ? (
          <Dropdown
            className="w-full md:w-auto"
            appearance="filled-darker"
            value={experienceFilter}
            onOptionSelect={(_evt, data) =>
              setExperienceFilter(data.selectedOptions[0]!)
            }
            style={{
              minWidth: "max-content",
              borderRadius: "999px",
            }}
          >
            {[...optionsSet].map((option) => (
              <FluentOption key={`experience-${option}`}>{option}</FluentOption>
            ))}
          </Dropdown>
        ) : null}
      </div>
      <div className="flex-1 flex items-start self-stretch overflow-hidden">
        <div
          className={`flex flex-col w-[300px] py-4 pr-2 flex-grow md:flex-grow-0 items-start self-stretch bg-white md:border-right-line gap-3 overflow-auto ${!hideSelected ? "hidden md:flex" : ""}`}
        >
          {filteredExperiences.map(
            ({
              id,
              companyName,
              companyLogoLink,
              jobTitle,
              startDate,
              endDate,
              engagementType,
            }) => {
              const isSelected = id === selectedExperienceId;
              const startString =
                dateStringToLocalDate(startDate)?.toLocaleDateString("en-US", {
                  month: "short",
                  year: "numeric",
                }) ?? "Unknown";
              const endString =
                dateStringToLocalDate(endDate)?.toLocaleDateString("en-US", {
                  month: "short",
                  year: "numeric",
                }) ?? "Present";

              return (
                <div
                  key={`experience-${id}`}
                  className={`flex flex-col p-3 items-start self-stretch gap-3 rounded-xl cursor-pointer bg-white ${isSelected ? "md:bg-[#F3F5F7]" : ""} hover:bg-[#D1D9E1]`}
                  onClick={() => {
                    id
                      ? (setSelectedExperienceId(id), setHideSelected(false))
                      : null;
                  }}
                >
                  <div className="flex items-center self-stretch gap-3">
                    <Avatar
                      image={{ src: companyLogoLink }}
                      name={companyName}
                      size={48}
                    />
                    <div className="flex flex-col items-start gap-1">
                      <p className="text-sm font-semibold text-[#22234A]">{`${jobTitle} at ${companyName}`}</p>
                      <p className="text-sm text-[#727E94]">{`${startString} - ${endString} (${engagementType})`}</p>
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
        <ExperienceEntry
          experience={selectedExperience}
          hideOnMobile={hideSelected}
          closeOnMobileFn={() => setHideSelected(true)}
        />
      </div>
    </div>
  );
};

const ExperienceEntry = ({
  experience,
  hideOnMobile,
  closeOnMobileFn,
}: {
  experience?: IExperience;
  hideOnMobile: boolean;
  closeOnMobileFn: () => void;
}) => {
  if (!experience) {
    return <div className="flex flex-col py-4 pl-5 items-start gap-5 flex-1" />;
  }

  const selectedStartDateString =
    dateStringToLocalDate(experience.startDate)?.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    }) ?? "Unknown";
  const selectedEndDateString =
    dateStringToLocalDate(experience.endDate)?.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
    }) ?? "Present";

  return (
    <div
      className={`${hideOnMobile ? "hidden md:flex" : "flex"} flex-col py-4 pl-5 self-stretch items-start gap-5 flex-1 overflow-auto`}
    >
      <div
        className="md:hidden min-h-7 min-w-7 cursor-pointer p-[2px] rounded-md hover:bg-[#d8d8d8]"
        onClick={closeOnMobileFn}
      >
        <ArrowLeftSVG />
      </div>
      <div className="flex items-center self-stretch gap-3">
        <Avatar
          image={{ src: experience.companyLogoLink }}
          name={experience.companyName}
          size={48}
        />
        <div className="flex flex-col gap-1 items-start self-stretch">
          <span className="text-base font-semibold text-[#22234A]">{`${experience?.jobTitle} at ${experience?.companyName}`}</span>
          <span className="text-sm font-medium text-[#727E94]">{`${selectedStartDateString} - ${selectedEndDateString} (${experience?.engagementType})`}</span>
          {experience.location ? (
            <div className="flex gap-1 items-center">
              <LocationSVG />
              <span className="text-sm font-medium text-[#4E4F6C]">
                {experience.location}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {experience.companyDescription ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">
            Company Overview
          </p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.companyDescription}
          </p>
        </div>
      ) : null}
      {experience.industries &&
      (experience.industries !== "Other" || experience.otherIndustries) ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">Industry</p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.industries && experience.industries !== "Other"
              ? experience.industries
              : experience.otherIndustries}
          </p>
        </div>
      ) : null}
      {experience.stage ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <p className="text-sm font-semibold text-[#22234A]">
            Stage when they joined
          </p>
          <p className="text-sm font-medium text-[#4E4F6C] whitespace-break-spaces">
            {experience.stage}
          </p>
        </div>
      ) : null}
      {experience.outcomes ? (
        <div className="flex flex-col gap-1 items-start self-stretch">
          <span className="text-sm font-semibold text-[#22234A]">
            Responsibilities and Outcomes
          </span>
          <span className="text-sm font-medium text-[#4E4F6C]  whitespace-break-spaces">
            {experience.outcomes}
          </span>
        </div>
      ) : null}
    </div>
  );
};

const InterviewQuestions = ({
  questions,
}: {
  questions: {
    question: string;
    why: string;
    lookingFor: string[];
  }[];
}) => {
  if (!questions.length) {
    return;
  }

  return (
    <div className="flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl">
      <div className="flex gap-1 pb-4 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          Interview Questions
        </p>
      </div>
      <div className="flex flex-col gap-1 items-start self-stretch">
        <ol className="list-[auto] pl-5">
          {questions.map(({ question, why, lookingFor }, idx) => {
            return (
              <li
                key={`skill-${idx}`}
                className="text-base font-medium text-[#22234A] py-4"
              >
                <p className="text-base font-medium text-[#22234A]">
                  {question}
                </p>
                <Accordion collapsible>
                  <AccordionItem value={`interview-${idx}`}>
                    <AccordionHeader>
                      <p className="text-base font-medium text-[#22234A]">
                        Why ask this question?
                      </p>
                    </AccordionHeader>
                    <AccordionPanel>
                      <p className="text-base font-medium text-[#22234A]">
                        Helps evaluate {why.toLocaleLowerCase()}
                      </p>
                      <p className="text-base font-medium text-[#22234A]">
                        High scoring answers: Listen for answers that reveal
                      </p>
                      <ul className="list-disc pl-5">
                        {lookingFor.map((response, responseIdx) => (
                          <li
                            key={`interview-${responseIdx}`}
                            className="text-sm font-medium text-[#22234A]"
                          >
                            {response}
                          </li>
                        ))}
                      </ul>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

const MapPersonalityHighlights = ({
  personalityResults,
  personalityDetail,
  personalityRoleStrength,
  personalityRoleStrengthDetail,
  behaviorResults,
  behaviorDetail,
  behaviorRoleStrength,
  behaviorRoleStrengthDetail,
  motivatorsResults,
  motivatorsDetails,
  motivatorRoleStrength,
  motivatorRoleStrengthDetail,
  cultureLink,
}: {
  personalityResults?: string;
  personalityDetail?: string;
  personalityRoleStrength?: string;
  personalityRoleStrengthDetail?: string;
  behaviorResults?: string;
  behaviorDetail?: string;
  behaviorRoleStrength?: string;
  behaviorRoleStrengthDetail?: string;
  motivatorsResults?: string;
  motivatorsDetails?: string;
  motivatorRoleStrength?: string;
  motivatorRoleStrengthDetail?: string;
  cultureLink?: string;
}) => {
  if (!personalityResults && !behaviorResults && !motivatorsResults) {
    return;
  }

  return (
    <div className="flex flex-col gap-4 p-6 items-start justify-center self-stretch bg-white rounded-xl">
      <div className="flex gap-1 pb-4 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          MAP Personality Highlights
        </p>
      </div>
      <PersonalityHighlight
        title={`Personality: ${personalityResults}`}
        subheading={personalityDetail}
        strength={personalityRoleStrength}
        detail={personalityRoleStrengthDetail}
        imageSource="/personality.png"
      />
      <PersonalityHighlight
        title={`Behavior: ${behaviorResults}`}
        subheading={behaviorDetail}
        strength={behaviorRoleStrength}
        detail={behaviorRoleStrengthDetail}
        imageSource="/behavior.png"
      />
      <PersonalityHighlight
        title={`Motivators: ${motivatorsResults}`}
        subheading={motivatorsDetails}
        strength={motivatorRoleStrength}
        detail={motivatorRoleStrengthDetail}
        imageSource="/motivators.png"
      />
      {cultureLink ? (
        <a
          className="edit-text"
          href={cultureLink}
          target="_blank"
          rel="noreferrer"
        >
          View MAP Results
        </a>
      ) : null}
    </div>
  );
};

const PersonalityHighlight = ({
  title,
  subheading,
  strength,
  detail,
  imageSource,
}: {
  title?: string;
  subheading?: string;
  strength?: string;
  detail?: string;
  imageSource: string;
}) => {
  if (!title) {
    return;
  }

  return (
    <div className="flex flex-col gap-5 self-stretch items-start pb-4 border-bottom-line">
      <div className="flex flex-col self-stretch items-start">
        <p className="text-base font-semibold text-[#22234A] leading-6">
          {title}
        </p>
        <p className="text-sm font-normal text-[#4E4F6C] leading-5">
          {subheading}
        </p>
      </div>
      {strength && detail ? (
        <div className="flex gap-3 self-stretch items-center">
          <img src={imageSource} className="h-12 w-12 rounded-full" />
          <div className="flex flex-col gap-1 self-stretch">
            <p className="text-sm font-semibold text-[#22234A] leading-6">
              {strength}
            </p>
            <p className="text-sm font-normal text-[#4E4F6C] leading-5">
              {detail}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const CoreCompetencies = ({
  topSkills,
  secondarySkills,
  additionalSkills,
}: {
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
}) => {
  if (
    !topSkills.length &&
    !secondarySkills.length &&
    !additionalSkills.length
  ) {
    return;
  }

  return (
    <div className="flex flex-col gap-4 p-6 items-start justify-center self-stretch bg-white rounded-xl">
      <div className="flex gap-1 pb-4 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          Core Competencies
        </p>
      </div>
      <div className="flex flex-col content-center items-start self-stretch">
        <SkillRow
          text="Top Skills"
          skills={topSkills}
          isLast={!secondarySkills.length && !additionalSkills.length}
        />
        <SkillRow
          text="Secondary Skills"
          skills={secondarySkills}
          isLast={!additionalSkills.length}
        />
        <SkillRow text="Additional Skills" skills={additionalSkills} isLast />
      </div>
    </div>
  );
};

const SkillRow = ({
  text,
  skills,
  isLast,
}: {
  text: string;
  skills: Skills[];
  isLast?: boolean;
}) => {
  if (!skills.length) {
    return;
  }

  return (
    <div
      className={`flex gap-1 self-stretch items-center justify-between min-h-12 py-4 ${isLast ? "" : "border-bottom-line"}`}
    >
      <div className="flex gap-3 items-center">
        <p className="text-sm font-semibold text-[#22234A]">{text}</p>
      </div>
      <p className="max-w-[70%] text-sm font-medium text-[#22234A] text-nowrap overflow-hidden overflow-ellipsis hover:overflow-visible hover:text-wrap">
        {skills.join(", ")}
      </p>
    </div>
  );
};

const Questionnaires = ({
  businessPrinciples,
  peerDescription,
  communicationSkills,
}: {
  businessPrinciples: BusinessPrinciple[];
  peerDescription: PeerDescription[];
  communicationSkills: CommunicationSkill[];
}) => {
  return (
    <div className="flex flex-col gap-4 p-6 justify-center self-stretch bg-white rounded-xl">
      <div className="flex gap-1 pb-4 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          Questionnaires
        </p>
      </div>
      <div className="flex flex-col gap-4 content-center items-stretch self-stretch">
        <QuestionnaireEntry
          question="Rank the top business principles that drive you"
          rankedList={businessPrinciples}
        />
        <QuestionnaireEntry
          question="How would your peers describe you?"
          rankedList={peerDescription}
        />
        <QuestionnaireEntry
          question="Communication Skills"
          rankedList={communicationSkills}
        />
      </div>
    </div>
  );
};

const QuestionnaireEntry = ({
  question,
  rankedList,
}: {
  question: string;
  rankedList?: string[];
}) => {
  if (!rankedList?.length) {
    return;
  }

  return (
    <div className="card p-3 gap-3 bg-[#003c320d]">
      <div className="flex flex-col gap-[2px] self-stretch items-start">
        <p className="text-sm font-semibold text-[#22234A]">{question}</p>
      </div>
      <div className="flex gap-[10px] p-3 self-stretch items-start bg-white rounded-xl">
        <div className="flex flex-col gap-1 items-start self-stretch">
          <ol className="list-[auto] pl-5 columns-2 gap-x-8">
            {rankedList.map((skill, idx) => (
              <li
                key={`skill-${idx}`}
                className="text-sm font-medium text-[#22234A]"
              >
                {skill}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

const References = ({
  references,
  candidateName,
}: {
  references?: IReference[];
  candidateName?: string;
}) => {
  if (!references?.length) {
    return;
  }

  const sortedReferences = [...references].sort((a, b) =>
    a.status === "Approved" && b.status !== "Approved"
      ? -1 // Order complete references first
      : b.status === "Approved" && a.status !== "Approved"
        ? 1
        : a.createdAt < b.createdAt // Otherwise, order most recent first
          ? 1
          : -1,
  );

  const sliderSettings = {
    infinite: true,
    dots: true,
    slidesToShow: Math.min(3, references.length),
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: Math.min(2, references.length),
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl">
      <div className="flex gap-1 pb-4 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          References
        </p>
      </div>
      <div className="block w-full max-w-full px-8 pb-6 overflow-hidden">
        <Slider {...sliderSettings}>
          {sortedReferences.map((reference, idx) => {
            return (
              <div
                className="flex flex-1 px-4"
                key={`reference-${reference.id ?? idx}`}
              >
                <Reference
                  {...reference}
                  candidateName={candidateName}
                  isLast
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

const Projects = ({
  projects,
}: {
  projects?: {
    id?: string;
    title?: string;
    summary?: string;
    link?: string;
    imageLink?: string;
  }[];
}) => {
  if (!projects?.length) {
    return;
  }

  return (
    <div className="flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl">
      <div className="flex gap-1 pb-4 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          Project
        </p>
      </div>
      {projects.map((project, idx) => {
        const isLast = idx + 1 === projects.length;

        return (
          <Project
            key={`project-${project.id ?? idx}`}
            {...project}
            isLast={isLast}
          />
        );
      })}
    </div>
  );
};

const Links = ({
  resumeLink,
  linkedIn,
  personalLink,
  email,
}: {
  resumeLink?: string;
  linkedIn?: string;
  personalLink?: string;
  email?: string;
}) => {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const notify = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          Email copied to clipboard
        </ToastTitle>
      </Toast>,
      { intent: "success" },
    );

  const links: {
    Icon: SvgJsxType;
    text: string;
    link: string;
  }[] = [];

  if (linkedIn) {
    links.push({
      Icon: LinkedInSVG,
      text: "LinkedIn",
      link: linkedIn,
    });
  }
  if (resumeLink) {
    links.push({
      Icon: DocumentTextBoldSVG,
      text: "Resume",
      link: resumeLink,
    });
  }
  if (personalLink) {
    links.push({
      Icon: MonitorMobileSVG,
      text: "Website",
      link: personalLink,
    });
  }

  return (
    <div className="flex-1 flex flex-col p-6 items-start justify-center self-stretch bg-white rounded-xl">
      <div className="flex gap-1 pb-4 items-center self-stretch border-bottom-line">
        <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
          Links
        </p>
      </div>
      <div className="flex flex-col md:flex-row md:gap-20 content-center items-start self-stretch md:border-bottom-line">
        {links.map((props) => (
          <LinkElement {...props} key={`links-${props.text}`} isLast />
        ))}
        {email ? (
          <div
            className="flex gap-1 self-stretch items-center justify-between min-h-12 py-4 cursor-pointer"
            onClick={() => {
              void navigator.clipboard.writeText(email);
              notify();
            }}
          >
            <div className="flex gap-3 items-center">
              <SmsSVG className="flex-shrink-0" height="20" width="20" />
              <p className="text-sm font-semibold text-[#22234A]">Copy Email</p>
            </div>
            <Toaster toasterId={toasterId} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const LinkElement = ({
  Icon,
  text,
  link,
  isLast,
}: {
  Icon: SvgJsxType;
  text: string;
  link: string;
  isLast?: boolean;
}) => {
  return (
    <div
      className={`flex gap-1 self-stretch items-center justify-between min-h-12 py-4 ${isLast ? "" : "border-bottom-line md:border-none"}`}
    >
      <div className="flex gap-3 items-center">
        <Icon className="flex-shrink-0" height="20" width="20" />
        <p className="text-sm font-semibold text-[#22234A]">{text}</p>
      </div>
      <a
        className="cursor-pointer"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        <ExportSVG height="20" width="20" />
      </a>
    </div>
  );
};

export default CandidateProfile;
