import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";
import networkErrorHandler from "../../utils/networkErrorHandler";
import { CandidateApplicationStatus } from "../../types";

export const workTypeList = [
  "Managing people",
  "Individual contributor",
] as const;
export type WorkType = (typeof workTypeList)[number];

export const roleList = [
  "Finance",
  "Operations",
  "Marketing",
  "Medical / Clinical",
  "Sales",
  "HR",
  "Product",
  "Data and Analytics",
  "Software Dev",
  "Information Security",
] as const;
export type Role = (typeof roleList)[number];

export const functionAreaList = [
  "Finance",
  "Operations",
  "Marketing",
  "Sales",
  "Software Engineering",
  "Product",
  "Data and Analytics",
  "HR",
] as const;
export type FunctionArea = (typeof functionAreaList)[number];

export const industryList = [
  "Biotechnology",
  "Clinical Services",
  "Health Insurance",
  "Healthcare Administration",
  "Healthcare Services",
  "Healthtech",
  "Hospitals",
  "Management and Engagement",
  "Medical Devices",
  "Medical Laboratories",
  "Pharmaceuticals",
  "Specialty Care",
  "Veterinary Services",
  "Other",
] as const;
export type Industry = (typeof industryList)[number];

export const financeSkillList = [
  // Strategic Financial Management
  "Financial Forecasting",
  "Budget Development",
  "Mergers & Acquisitions (M&A) Strategy",
  "Corporate Restructuring",
  "Investor Relations",
  // Accounting & Compliance
  "Tax Planning and Compliance",
  "Internal Controls",
  "Audit Coordination",
  "Cost Accounting",
  "SEC Filings",
  // Financial Analysis & Decision Support
  "Competitive Analysis",
  "Profitability Analysis",
  "Financial Modeling",
  "Business Valuation",
  "Return on Investment (ROI) Calculations",
  // Operational Finance
  "Cash Flow Management",
  "Working Capital Management",
  "Performance Benchmarking",
  "Financial Systems Implementation",
  "Process Improvement",
  // Leadership & Communication
  "Strategic Leadership",
  "Financial Policy Development",
  "Cross-Functional Team Leadership",
  "Executive Board Communication",
  "Negotiation Skills",
] as const;
export type FinanceSkill = (typeof financeSkillList)[number];

export const operationsSkillList = [
  // Strategic Operations Management
  "Operational Strategy Development",
  "Supply Chain Strategy",
  "Cost Efficiency Strategies",
  "Performance Metrics Development",
  "Quality Assurance Programs",
  // Process Optimization
  "Six Sigma Methodologies",
  "Automation Technologies",
  "Continuous Improvement",
  "Change Management",
  "Project Management",
  // Supply Chain & Logistics
  "Inventory Management",
  "Procurement and Purchasing Strategies",
  "Logistics Coordination",
  "Distribution Network Design",
  "Demand Forecasting",
  // People Management
  "Team Leadership",
  "Cross-functional Team Coordination",
  "Talent Development",
  "Organizational Design",
  "Performance Evaluation Systems",
  // Financial & Resource Management
  "Budget Management",
  "Financial Forecasting",
  "Asset Management",
  "ROI Analysis",
  "Resource Allocation",
] as const;
export type OperationsSkill = (typeof operationsSkillList)[number];

export const marketingSkillList = [
  // Strategic Marketing Management
  "Marketing Strategy Development",
  "Market Research and Analysis",
  "Customer Segmentation",
  "Product Positioning",
  "Pricing Strategy",
  // Digital Marketing
  "Search Engine Optimization (SEO)",
  "Social Media Marketing",
  "Email Marketing",
  "Digital Analytics and Reporting",
  "Conversion Rate Optimization",
  // Creative Direction
  "Creative Campaign Development",
  "Graphic Design Oversight",
  "Brand Storytelling",
  "User Experience (UX) Design",
  "User Interface (UI) Design",
  // Communication & Public Relations
  "Public Relations Strategy",
  "Corporate Communications",
  "Influencer Partnerships",
  "Community Management",
  "Internal Communications",
  // Analytics & Insights
  "Data Analysis",
  "Market Trends Forecasting",
  "Consumer Behavior Analysis",
  "Customer Journey Mapping",
  "ROI Measurement",
] as const;
export type MarketingSkill = (typeof marketingSkillList)[number];

export const salesSkillList = [
  // Strategic Sales Leadership
  "Sales Strategy Development",
  "Market Penetration Strategies",
  "Sales Forecasting",
  "Pricing Strategy Development",
  "Contract Negotiation and Management",
  // Business Development
  "Partnership Development",
  "New Market Entry",
  "Client Acquisition Strategies",
  "Account Management",
  "Customer Relationship Management (CRM)",
  // Sales Execution & Techniques
  "Consultative Sales Techniques",
  "Sales Pitch Development",
  "Objection Handling",
  "Closing Techniques",
  "Sales Presentation Skills",
  // Sales Team Management
  "Sales Team Leadership",
  "Recruitment and Training of Sales Staff",
  "Performance Management",
  "Team Building",
  "Sales Meeting Facilitation",
  // Analytics and Technology
  "Data-Driven Decision Making",
  "Forecasting Models",
  "Customer Data Analysis",
  "Sales Reporting",
  "Sales Automation Tools",
] as const;
export type SalesSkill = (typeof salesSkillList)[number];

export const softwareEngineeringSkillList = [
  // Strategic Technology Leadership
  "Technology Strategy and Vision",
  "Technology Roadmap Development",
  "Digital Transformation",
  "Intellectual Property Strategy",
  "R&D Management",
  // Software Engineering
  "Software Architecture Design",
  "DevOps Practices",
  "System Scalability",
  "Performance Optimization",
  "Security-Oriented Design",
  // Product Management
  "Product Roadmap Planning",
  "User-Centered Design",
  "Cross-Functional Team Leadership",
  "Product Metrics and Analytics",
  "Market Analysis",
  // Technical Skill Proficiency
  "Database Management",
  "Cloud Computing (AWS, Azure, Google Cloud)",
  "Network Architecture and Administration",
  "Cybersecurity Protocols",
  "Data Science and Analytics",
  // Team and Resource Management
  "Technical Team Leadership",
  "Talent Acquisition and Retention",
  "Training and Development",
  "Budget Management",
  "Outsourcing and Vendor Management",
] as const;
export type SoftwareEngineeringSkill =
  (typeof softwareEngineeringSkillList)[number];

export const productSkillList = [
  // Product Strategy and Vision
  "Visionary Product Leadership",
  "Strategic Roadmap Development",
  "Market Trend Analysis",
  "Competitor Analysis",
  "Scalability Strategies",
  // Product Development
  "User Story Mapping",
  "Sprint Planning and Execution",
  "MVP (Minimum Viable Product) Creation",
  "Design Thinking",
  "Quality Assurance",
  // User Experience and Design
  "User-Centered Design",
  "Usability Testing",
  "User Research",
  "Journey Mapping",
  "Visual Design",
  // Customer and Market Insights
  "Customer Research",
  "Market Segmentation",
  "Quantitative Analysis",
  "Qualitative Analysis",
  "Consumer Behavior Analysis",
  // Leadership and Cross-Functional Collaboration
  "Cross-Functional Team Leadership",
  "Stakeholder Management",
  "Organizational Influence",
  "Change Management",
  "Training and Development",
] as const;
export type ProductSkill = (typeof productSkillList)[number];

export const dataAndAnalyticsSkillList = [
  // Data Strategy and Management
  "Data Strategy Development",
  "Data Architecture Design",
  "Data Quality Assurance",
  "Data Warehousing",
  "Data Security Management",
  // Analytics and Business Intelligence
  "Business Intelligence Implementation",
  "Statistical Analysis",
  "Data Visualization",
  "ROI Analysis for Data Initiatives",
  "Advanced Analytics Techniques",
  // Machine Learning and Artificial Intelligence
  "Machine Learning Algorithms",
  "AI Strategy Development",
  "Natural Language Processing (NLP)",
  "Model Training and Evaluation",
  "AI-driven Automation",
  // Data Integration and Engineering
  "Data Integration",
  "Data Pipeline Development",
  "Real-time Data Processing",
  "Cloud Data Solutions",
  "IoT Data Management",
  // Leadership and Organizational Development
  "Team Leadership",
  "Talent Acquisition and Development",
  "Stakeholder Engagement",
  "Vendor and Partnership Management",
  "Organizational Data Culture Promotion",
] as const;
export type DataAndAnalyticsSkill = (typeof dataAndAnalyticsSkillList)[number];

export const hrSkillList = [
  // Strategic HR Leadership
  "Organizational Design and Development",
  "Workforce Planning",
  "HR Policy Formulation",
  "Compensation Strategy",
  "Benefits Design",
  // Talent Management
  "Talent Acquisition",
  "Employee Onboarding",
  "Performance Management Systems",
  "Talent Pool Analysis",
  "Exit Management",
  // Employee Relations and Engagement
  "Employee Relations",
  "Conflict Resolution",
  "Culture Initiatives",
  "Internal Communications",
  "Employee Recognition Programs",
  // HR Operations and Administration
  "Payroll Management",
  "Records Management",
  "Benefits Administration",
  "Data Privacy and Security in HR",
  "Budgeting for HR",
  // Learning and Development
  "Training Program Development",
  "E-learning Strategies",
  "Leadership Training Programs",
  "Performance Improvement",
  "Learning Management Systems (LMS)",
] as const;
export type HrSkill = (typeof hrSkillList)[number];

export const skillsList = [
  ...financeSkillList,
  ...operationsSkillList,
  ...marketingSkillList,
  ...salesSkillList,
  ...softwareEngineeringSkillList,
  ...productSkillList,
  ...dataAndAnalyticsSkillList,
  ...hrSkillList,
] as const;
export type Skills =
  | FinanceSkill
  | OperationsSkill
  | MarketingSkill
  | SalesSkill
  | SoftwareEngineeringSkill
  | ProductSkill
  | DataAndAnalyticsSkill
  | HrSkill;

export const healthcareIndustryHeaderList = [
  "Clinical Services",
  "Technology and Data",
  "Specialty Care",
  "Healthcare Administration",
  "Management and Engagement",
  "None",
] as const;
export type HealthcareIndustryHeader =
  (typeof healthcareIndustryHeaderList)[number];

export const healthcareIndustryList = [
  "Hospitals",
  "Outpatient Care",
  "Elderly Care",
  "Rehabilitation",
  "Emergency Medicine",

  "Telehealth",
  "Electronic Health Record",
  "Remote Patient Monitoring",
  "Health Data and Analytics",
  "Medical Devices",

  "Oncology",
  "Cardiology",
  "Mental Health",
  "Nutrition",
  "Pediatrics",
  "Pharmaceuticals",

  "Healthcare Consulting",
  "Supply Chain Management",
  "Healthcare Insurance and Benefits",
  "Healthcare Policy and Advocacy",

  "Care Coordination",
  "Patient Engagement",
  "Medication Management",
  "Referral Management",
] as const;
export type HealthcareIndustry = (typeof healthcareIndustryList)[number];

export const stageList = [
  "Pre Seed",
  "Seed",
  "Series A",
  "Series B",
  "Series C",
  "Later Stage Funding",
  "Private Equity Funding",
  "Not Funded",
  "Public",
  "Private",
] as const;
export type Stage = (typeof stageList)[number];

// export const workExperiencesList = [
//   "Pre Seed",
//   "Seed",
//   "Series A",
//   "Series B",
//   "Series C",
//   "Series D",
//   "Acquisition / Merger",
//   "PE Backing",
//   "IPO",
//   "High Growth",
//   "New Product Launches",
//   "Pivots",
//   "Turnaround / Restructuring",
// ] as const;
// export type WorkExperience = (typeof workExperiencesList)[number];

export const useCaseList = [
  "Leading product launches and innovation efforts",
  "Developing new processes or products",
  "Enhancing efficiency through automation",
  "Leveraging business development network / sales",
  "Analyzing data to derive insights",
  "Managing and analyzing finances",
  "Developing functional strategies",
] as const;
export type UseCase = (typeof useCaseList)[number];

export const companySizeList = [
  "<$1M",
  "$1M-$3M",
  "$3M-$10M",
  "$10M-$25M",
  "$25M-$50M",
  "$50M+",
  "Any Size",
] as const;
export type CompanySize = (typeof companySizeList)[number];

export const companyEmployeesList = [
  "1-10",
  "11-25",
  "26-50",
  "51-200",
  "201-500",
  "500+",
] as const;
export type CompanyEmployees = (typeof companyEmployeesList)[number];

export const referenceRelationshipList = [
  "Manager",
  "Peer",
  "Coworker",
  "Direct Report",
  "Investor",
  "Board Member",
  "Client",
] as const;
export type ReferenceRelationship = (typeof referenceRelationshipList)[number];

export const referenceStatusList = [
  "Requested",
  "No Response",
  "Responded",
  "Approved",
] as const;
export type ReferenceStatus = (typeof referenceStatusList)[number];

export const businessPrincipleList = [
  "Leadership",
  "Communication",
  "Customer Focus",
  "Innovation",
  "Accountability",
  "Flexibility",
  "Teamwork",
  "Work-Life Balance",
] as const;
export type BusinessPrinciple = (typeof businessPrincipleList)[number];

export const peerDescriptionList = [
  "Trustworthy",
  "Customer-focused",
  "Team-oriented",
  "Reliable",
  "Adaptable",
  "Resilient",
  "Open to feedback",
  "Strategic",
] as const;
export type PeerDescription = (typeof peerDescriptionList)[number];

export const communicationSkillList = [
  "Written communication",
  "Verbal communication",
  "Presentation skills",
] as const;
export type CommunicationSkill = (typeof communicationSkillList)[number];

export const engagementTypeList = [
  "Part-Time",
  "Full-Time",
  "Consultant",
  "Advisor",
  "Board",
] as const;
export type EngagementType = (typeof engagementTypeList)[number];

export interface IExperience {
  id?: string;
  companyName?: string;
  companyLogoLink?: string;
  companyDescription?: string;
  industries?: Industry | "";
  otherIndustries?: string;
  stage?: Stage | "";
  jobTitle?: string;
  engagementType?: EngagementType;
  functions?: FunctionArea[];
  location?: string;
  startDate?: string;
  endDate?: string;
  outcomes?: string;
}

export interface IExperienceAddRequest {
  companyName?: string;
  companyLogoLink?: string;
  companyDescription?: string;
  industries?: Industry;
  otherIndustries?: string;
  stage?: Stage;
  jobTitle?: string;
  engagementType?: EngagementType;
  functions?: FunctionArea[];
  location?: string;
  startDate?: string;
  endDate?: string;
  outcomes?: string;
}

export interface IReference {
  id?: string;
  createdAt: string;
  name?: string;
  title?: string;
  company?: string;
  relationship?: ReferenceRelationship;
  email?: string;
  requestMessage?: string;
  managerResponse1?: string;
  managerResponse2?: string;
  managerResponse3?: string;
  managerResponse4?: string;
  directReportResponse1?: string;
  directReportResponse2?: string;
  directReportResponse3?: string;
  directReportResponse4?: string;
  clientResponse1?: string;
  clientResponse2?: string;
  clientResponse3?: string;
  clientResponse4?: string;
  peerCoworkerResponse1?: string;
  peerCoworkerResponse2?: string;
  peerCoworkerResponse3?: string;
  peerCoworkerResponse4?: string;
  boardMemberInvestorResponse1?: string;
  boardMemberInvestorResponse2?: string;
  boardMemberInvestorResponse3?: string;
  boardMemberInvestorResponse4?: string;
  status?: ReferenceStatus;
}

interface IProject {
  id?: string;
  title?: string;
  summary?: string;
  link?: string;
  imageLink?: string;
}

export interface IPerson {
  name?: string;
  emails: string[];
  profilePictureLink?: string;
  titleAndEmployer?: string;
  yearsOfExperience?: number;
  yearsOfHealthcareExperience?: number;
  resumeLink?: string;
  linkedIn?: string;
  cultureLink?: string;
  personalWebsite?: string;
  biography?: string;
  idealWorkType: WorkType[];
  idealRoles: Role[];
  idealRolesOther?: string;
  idealSubindustries: HealthcareIndustry[];
  idealUseCase: UseCase[];
  idealCompanyHeadcount: CompanyEmployees[];
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
  availabilityFullTime?: boolean;
  availabilityFractional?: boolean;
  availabilityAdvisory?: boolean;
  availabilityBoard?: boolean;
  availabilityTime?: string;
  availabilityStartDate?: string;
  retainerDaily?: string;
  retainerHourly?: string;
  location?: string;
  questionnaireBusinessPrinciples: BusinessPrinciple[];
  questionnaireTeams?: string;
  questionnaireSeparatesFromPeers?: string;
  questionnairePeerDescription: PeerDescription[];
  questionnaireCommunicationSkills: CommunicationSkill[];
  personalityResults?: string;
  personalityDetail?: string;
  behaviorResults?: string;
  behaviorDetail?: string;
  motivatorsResults?: string;
  motivatorsDetails?: string;
}

export interface ICandidateObject extends IPerson {
  experiences: IExperience[];
  references: IReference[];
  projects: IProject[];
}

interface IJobOpening {
  id?: string;
  companyRecordId?: string;
  role?: string;
  jobDescriptionLink?: string;
  scope?: string;
  budget?: string;
  desiredStartDate?: string;
  convertsToFullTime?: ConvertsToFullTime;
  matchCriteria1?: string;
  matchCriteria2?: string;
  matchCriteria3?: string;
  status?: JobOpeningStatus;
}

export const convertsToFullTimeList = ["Yes", "No", "Unsure"] as const;
export type ConvertsToFullTime = (typeof convertsToFullTimeList)[number];

export const jobOpeningStatusList = [
  "New Opening",
  "Creating Job Spec",
  "Identifying Candidates",
  "Presenting Shortlist",
  "Role Filled (won)",
  "Role Filled (lost)",
] as const;
export type JobOpeningStatus = (typeof jobOpeningStatusList)[number];

interface ICandidateStatus {
  candidateRecordId?: string;
  jobOpeningRecordId?: string;
  status?: CandidateApplicationStatus;
  scope?: string;
  agreesWithScope?: boolean;
  budget?: string;
  agreesWithPay?: boolean;
  earliestStartDate?: string;
  agreesWithStartDate?: boolean;
  openToFullTime?: boolean;
  matchCriteria1?: string;
  matchCriteria1Quote?: string;
  matchCriteria2?: string;
  matchCriteria2Quote?: string;
  matchCriteria3?: string;
  matchCriteria3Quote?: string;
  personalityRoleStrength?: string;
  personalityRoleStrengthDetail?: string;
  behaviorRoleStrength?: string;
  behaviorRoleStrengthDetail?: string;
  motivatorRoleStrength?: string;
  motivatorRoleStrengthDetail?: string;
}

interface ICandidateResponseObject {
  candidate: ICandidateObject;
  company: {
    name?: string;
  };
  jobOpening: IJobOpening;
  candidateStatus: ICandidateStatus;
}

interface IReferenceAddObject {
  referenceName: string;
  email: string;
  relationship: ReferenceRelationship;
  company: string;
  title: string;
  message: string;
}

// Define a type for the slice state
interface ICandidateProfileState extends ICandidateResponseObject {
  status: "idle" | "pending" | "succeeded" | "failed";
  postingStatus: "idle" | "posting" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: ICandidateProfileState = {
  candidate: {
    emails: [],
    experiences: [],
    references: [],
    projects: [],
    idealWorkType: [],
    idealRoles: [],
    idealSubindustries: [],
    idealUseCase: [],
    idealCompanyHeadcount: [],
    topSkills: [],
    secondarySkills: [],
    additionalSkills: [],
    questionnaireBusinessPrinciples: [],
    questionnairePeerDescription: [],
    questionnaireCommunicationSkills: [],
  },
  company: {},
  jobOpening: {},
  candidateStatus: {},
  status: "idle",
  postingStatus: "idle",
};

export const getCandidateOpeningProfile = createAsyncThunk(
  "candidateProfile/get",
  async (
    { id, signal }: { id: string; signal: AbortSignal },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: ICandidateResponseObject } = await axios.get(
        `/api/jobOpening/candidateStatus/${id}`,
        { signal },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const getCandidateProfile = createAsyncThunk(
  "candidateProfile/get",
  async (
    {
      id,
      token,
      signal,
    }: {
      id: string;
      token: string;
      signal: AbortSignal;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: ICandidateResponseObject } = await axios.get(
        `/api/candidate/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
          signal,
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const updateCandidateProfile = createAsyncThunk(
  "candidateProfile/update",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: (Partial<IPerson> & { emails: string[] }) | FormData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: IPerson } = await axios.patch(
        `/api/candidate/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const addCandidateReference = createAsyncThunk(
  "candidateProfile/addReference",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: IReferenceAddObject;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: IReference } = await axios.post(
        `/api/candidate/${id}/reference`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const addCandidateProject = createAsyncThunk(
  "candidateProfile/addProject",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: FormData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: IProject } = await axios.post(
        `/api/candidate/${id}/project`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const updateCandidateProject = createAsyncThunk(
  "candidateProfile/updateProject",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: FormData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: IProject } = await axios.patch(
        `/api/candidate/foo/project/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const deleteCandidateProject = createAsyncThunk(
  "candidateProfile/deleteProject",
  async ({ id, token }: { id: string; token: string }, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/candidate/foo/project/${id}`, {
        headers: {
          Authorization: `Bearer ${token},`,
        },
      });

      return { id };
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const addCandidateExperience = createAsyncThunk(
  "candidateProfile/addExperience",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: FormData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: IExperience } = await axios.post(
        `/api/candidate/${id}/experience`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const updateCandidateExperience = createAsyncThunk(
  "candidateProfile/updateExperience",
  async (
    {
      id,
      data,
      token,
    }: {
      id: string;
      data: FormData | IExperienceAddRequest;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: IExperience } = await axios.patch(
        `/api/candidate/foo/experience/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const deleteCandidateExperience = createAsyncThunk(
  "candidateProfile/deleteExperience",
  async ({ id, token }: { id: string; token: string }, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/candidate/foo/experience/${id}`, {
        headers: {
          Authorization: `Bearer ${token},`,
        },
      });

      return { id };
    } catch (error) {
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

const sortExperiences = (a: IExperience, b: IExperience): number => {
  if (!a.endDate && b.endDate) {
    return -1;
  } else if (a.endDate && !b.endDate) {
    return 1;
  } else if ((a.endDate ?? "") < (b.endDate ?? "")) {
    return 1;
  } else if ((a.endDate ?? "") > (b.endDate ?? "")) {
    return -1;
  } else if ((a.startDate ?? "") < (b.startDate ?? "")) {
    return 1;
  } else if ((a.startDate ?? "") > (b.startDate ?? "")) {
    return -1;
  }

  return 0;
};

export const candidateProfileSlice = createSlice({
  name: "candidateProfile",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetPostingStatus: (state) => {
      state.postingStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCandidateOpeningProfile.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getCandidateOpeningProfile.fulfilled,
        (
          state,
          action: PayloadAction<ICandidateResponseObject | undefined>,
        ) => {
          state.candidate = {
            emails: [],
            experiences: [],
            references: [],
            projects: [],
            idealWorkType: [],
            idealRoles: [],
            idealSubindustries: [],
            idealUseCase: [],
            idealCompanyHeadcount: [],
            topSkills: [],
            secondarySkills: [],
            additionalSkills: [],
            questionnaireBusinessPrinciples: [],
            questionnairePeerDescription: [],
            questionnaireCommunicationSkills: [],
            ...action.payload?.candidate,
          };
          state.candidate.experiences =
            action.payload?.candidate.experiences.sort(sortExperiences) ?? [];
          state.company = action.payload?.company ?? {};
          state.jobOpening = action.payload?.jobOpening ?? {};
          state.candidateStatus = action.payload?.candidateStatus ?? {};
          state.status = "succeeded";
        },
      )
      .addCase(getCandidateOpeningProfile.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateCandidateProfile.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        updateCandidateProfile.fulfilled,
        (state, action: PayloadAction<IPerson | undefined>) => {
          state.postingStatus = "succeeded";
          if (action.payload) {
            state.candidate = {
              ...action.payload,
              experiences: state.candidate.experiences,
              references: state.candidate.references,
              projects: state.candidate.projects,
            };
          }
        },
      )
      .addCase(updateCandidateProfile.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(addCandidateReference.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        addCandidateReference.fulfilled,
        (state, action: PayloadAction<IReference | undefined>) => {
          state.postingStatus = "succeeded";
          state.candidate.references = [
            ...state.candidate.references,
            action.payload!,
          ];
        },
      )
      .addCase(addCandidateReference.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(addCandidateProject.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        addCandidateProject.fulfilled,
        (state, action: PayloadAction<IProject | undefined>) => {
          state.postingStatus = "succeeded";
          state.candidate.projects = [
            ...state.candidate.projects,
            action.payload!,
          ];
        },
      )
      .addCase(addCandidateProject.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(updateCandidateProject.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        updateCandidateProject.fulfilled,
        (state, action: PayloadAction<IProject | undefined>) => {
          state.postingStatus = "succeeded";
          const projectIndex = state.candidate.projects.findIndex(
            ({ id }) => id === action.payload?.id,
          );

          if (projectIndex !== -1) {
            state.candidate.projects[projectIndex] = action.payload!;
          }
        },
      )
      .addCase(updateCandidateProject.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(deleteCandidateProject.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        deleteCandidateProject.fulfilled,
        (state, action: PayloadAction<IProject | undefined>) => {
          state.postingStatus = "succeeded";
          state.candidate.projects = state.candidate.projects.filter(
            ({ id }) => id !== action.payload?.id,
          );
        },
      )
      .addCase(deleteCandidateProject.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(addCandidateExperience.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        addCandidateExperience.fulfilled,
        (state, action: PayloadAction<IExperience | undefined>) => {
          state.postingStatus = "succeeded";
          state.candidate.experiences = [
            ...state.candidate.experiences,
            action.payload!,
          ].sort(sortExperiences);
        },
      )
      .addCase(addCandidateExperience.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(updateCandidateExperience.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        updateCandidateExperience.fulfilled,
        (state, action: PayloadAction<IExperience | undefined>) => {
          state.postingStatus = "succeeded";

          if (action.payload !== undefined) {
            const index = state.candidate.experiences.findIndex(
              ({ id }) => id === action.payload!.id,
            );

            if (index !== -1) {
              state.candidate.experiences[index] = action.payload;
            }
          }

          state.candidate.experiences.sort(sortExperiences);
        },
      )
      .addCase(updateCandidateExperience.rejected, (state) => {
        state.postingStatus = "failed";
      })
      .addCase(deleteCandidateExperience.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        deleteCandidateExperience.fulfilled,
        (state, action: PayloadAction<IProject | undefined>) => {
          state.postingStatus = "succeeded";
          state.candidate.experiences = state.candidate.experiences.filter(
            ({ id }) => id !== action.payload?.id,
          );
        },
      )
      .addCase(deleteCandidateExperience.rejected, (state) => {
        state.postingStatus = "failed";
      });
  },
});

export const { resetPostingStatus } = candidateProfileSlice.actions;

export default candidateProfileSlice.reducer;
