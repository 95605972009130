import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const filters = [
  <ReferenceInput key="user" source="user" reference="users" />,
  <ReferenceInput key="company" source="company" reference="companies" />,
  <DateInput key="created_at" source="created_at" />,
];

export const CompanyUserList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="user" reference="users" />
      <ReferenceField source="company" reference="companies" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const CompanyUserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="user" reference="users" />
      <ReferenceField source="company" reference="companies" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const CompanyUserCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" />
      <ReferenceInput source="company" reference="companies" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const CompanyUserEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" />
      <ReferenceInput source="company" reference="companies" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
