import { Option, OptionGroup } from "@fluentui/react-components";
import {
  Role,
  Skills,
  dataAndAnalyticsSkillList,
  financeSkillList,
  hrSkillList,
  marketingSkillList,
  operationsSkillList,
  productSkillList,
  salesSkillList,
  softwareEngineeringSkillList,
} from "../redux/features/candidateProfileSlice";

const generateSkillOptionsLists = ({
  roles,
  list,
  topSkills,
  secondarySkills,
  additionalSkills,
  maxSelections,
}: {
  roles: Role[];
  list: "top" | "secondary" | "additional";
  topSkills: Skills[];
  secondarySkills: Skills[];
  additionalSkills: Skills[];
  maxSelections: number;
}) => {
  const selectedOptions =
    list === "top"
      ? topSkills
      : list === "secondary"
        ? secondarySkills
        : additionalSkills;
  const fallback =
    !roles.length || (roles.length === 1 && roles[0] === "Medical / Clinical");

  // Create an Option and disable it if we've reached the maximum number of selections
  const skillOptionGenerator = (option: Skills) => {
    let optionText: JSX.Element;

    if (list !== "top" && topSkills.includes(option)) {
      optionText = (
        <>
          {option} <b>(Top)</b>
        </>
      );
    } else if (list !== "secondary" && secondarySkills.includes(option)) {
      optionText = (
        <>
          {option} <b>(Secondary)</b>
        </>
      );
    } else if (list !== "additional" && additionalSkills.includes(option)) {
      optionText = (
        <>
          {option} <b>(Additional)</b>
        </>
      );
    } else {
      optionText = <>{option}</>;
    }

    return (
      <Option
        key={`skill-${option}`}
        value={option}
        text={option}
        disabled={
          selectedOptions.length >= maxSelections &&
          !selectedOptions.includes(option)
        }
      >
        {optionText}
      </Option>
    );
  };

  let allSkills: Skills[] = [];
  let skillsList: Skills[] = [];
  const skillsOptions: JSX.Element[] = [];

  // For each role, list all skills
  if (fallback || roles.includes("Finance")) {
    skillsList = [...financeSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-finance`}
        label={<span className="text-lg">Finance</span>}
      >
        <OptionGroup label="Strategic Financial Management">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Accounting & Compliance">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Financial Analysis & Decision Support">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Operational Finance">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Leadership & Communication">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  if (fallback || roles.includes("Operations")) {
    skillsList = [...operationsSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-operations`}
        label={<span className="text-lg">Operations</span>}
      >
        <OptionGroup label="Strategic Operations Management">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Process Optimization">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Supply Chain & Logistics">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="People Management">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Financial & Resource Management">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  if (fallback || roles.includes("Marketing")) {
    skillsList = [...marketingSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-marketing`}
        label={<span className="text-lg">Marketing</span>}
      >
        <OptionGroup label="Strategic Marketing Management">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Digital Marketing">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Creative Direction">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Communication & Public Relations">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Analytics & Insights">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  if (fallback || roles.includes("Sales")) {
    skillsList = [...salesSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-sales`}
        label={<span className="text-lg">Sales</span>}
      >
        <OptionGroup label="Strategic Sales Leadership">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Business Development">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Sales Execution & Techniques">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Sales Team Management">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Analytics and Technology">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  if (
    fallback ||
    roles.includes("Software Dev") ||
    roles.includes("Information Security")
  ) {
    skillsList = [...softwareEngineeringSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-engineering`}
        label={<span className="text-lg">Software Engineering</span>}
      >
        <OptionGroup label="Strategic Technology Leadership">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Software Engineering">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Product Management">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Technical Skill Proficiency">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Team and Resource Management">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  if (fallback || roles.includes("Product")) {
    skillsList = [...productSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-product`}
        label={<span className="text-lg">Product</span>}
      >
        <OptionGroup label="Product Strategy and Vision">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Product Development">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="User Experience and Design">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Customer and Market Insights">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Leadership and Cross-Functional Collaboration">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  if (
    fallback ||
    roles.includes("Data and Analytics") ||
    roles.includes("Information Security")
  ) {
    skillsList = [...dataAndAnalyticsSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-analytics`}
        label={<span className="text-lg">Data and Analytics</span>}
      >
        <OptionGroup label="Data Strategy and Management">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Analytics and Business Intelligence">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Machine Learning and Artificial Intelligence">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Data Integration and Engineering">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Leadership and Organizational Development">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  if (fallback || roles.includes("HR")) {
    skillsList = [...hrSkillList];
    allSkills = [...allSkills, ...skillsList];
    skillsOptions.push(
      <OptionGroup
        key={`skill-list-${list}-hr`}
        label={<span className="text-lg">HR</span>}
      >
        <OptionGroup label="Strategic HR Leadership">
          {skillsList.slice(0, 5).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Talent Management">
          {skillsList.slice(5, 10).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Employee Relations and Engagement">
          {skillsList.slice(10, 15).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="HR Operations and Administration">
          {skillsList.slice(15, 20).map(skillOptionGenerator)}
        </OptionGroup>
        <OptionGroup label="Learning and Development">
          {skillsList.slice(20, 25).map(skillOptionGenerator)}
        </OptionGroup>
      </OptionGroup>,
    );
  }

  return {
    options: skillsOptions,
    skillsList: allSkills,
  };
};

export default generateSkillOptionsLists;
