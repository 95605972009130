import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Button } from "@fluentui/react-components";

const Login = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/landing");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
      });
    })();

  const handleSignUp = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  if (isLoading || isAuthenticated) {
    return;
  }

  return (
    <div className="flex flex-col gap-4 max-w-[800px] text-center justify-center m-auto min-h-full">
      <h1 className="text-4xl font-semibold text-[#22234A]">
        Welcome to Venturous
      </h1>
      <div className="flex flex-col gap-4 items-center">
        <Button
          style={{ height: "50px", width: "300px", fontSize: "20px" }}
          appearance="primary"
          size="medium"
          onClick={handleLogin}
        >
          Log In
        </Button>
        <Button
          style={{ height: "50px", width: "300px", fontSize: "20px" }}
          appearance="secondary"
          size="medium"
          onClick={handleSignUp}
        >
          Sign Up
        </Button>
      </div>
    </div>
  );
};

export default Login;
