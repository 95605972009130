import { ReOrderDotsVerticalFilled } from "@fluentui/react-icons";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Executive } from "../redux/features/companyProfileSlice";
import { Avatar, Card } from "@fluentui/react-components";

const SortableItem = (props: { id: number; executive: Executive }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="w-auto flex !flex-row items-center gap-2"
    >
      <div className="h-6 w-6 flex justify-center" {...listeners}>
        <ReOrderDotsVerticalFilled className="m-auto" />
      </div>
      <div className="flex-1 flex gap-2 items-center justify-between">
        <Avatar
          image={{ src: props.executive.profilePicture }}
          name={props.executive.name}
          size={48}
        />
        <div className="flex flex-col gap-1 items-end">
          <p className="text-base font-semibold text-[#22234A] leading-6">
            {props.executive.name}
          </p>
          {props.executive.role ? (
            <p className="text-base font-normal text-[#727E94] leading-5">
              {props.executive.role}
            </p>
          ) : null}
        </div>
      </div>
    </Card>
  );
};

const ReorderableExecutiveTeam = ({
  executives,
  update,
}: {
  executives: Executive[];
  update: (update: Executive[]) => void;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent, list: Executive[]): void => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      // Ids are idx + 1 because ids of 0 seem to cause issues
      const oldIndex = (active.id as number) - 1;
      const newIndex = (over.id as number) - 1;

      update(arrayMove(list, oldIndex, newIndex));
    }
  };

  return (
    <div className="p-1 flex flex-col gap-2 overflow-auto">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => handleDragEnd(event, executives)}
      >
        <SortableContext
          items={executives.map((_, idx) => idx + 1)}
          strategy={verticalListSortingStrategy}
        >
          {executives.map((executive, idx) => (
            <SortableItem
              key={`executive-${executive.id ?? idx}`}
              // ID of 0 seems to cause issues, so increment by 1
              id={idx + 1}
              executive={executive}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ReorderableExecutiveTeam;
