import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EmailField,
  List,
  SimpleForm,
  SimpleShowLayout,
  Show,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";

const filters = [
  <TextInput key="email" source="email" />,
  <TextInput key="name" source="name" />,
  <TextInput key="profile_picture_link" source="profile_picture_link" />,
  <TextInput key="linkedin" source="linkedin" />,
  <TextInput key="culture_link" source="culture_link" />,
  <TextInput key="location" source="location" />,
  <TextInput key="auth0_id" source="auth0_id" />,
  <BooleanInput key="is_admin" source="is_admin" />,
  <DateInput key="created_at" source="created_at" />,
];

export const UserList = () => (
  <List filters={filters}>
    <Datagrid>
      <EmailField source="email" />
      <TextField source="name" />
      <UrlField source="profile_picture_link" content="Link" />
      <UrlField source="linkedin" content="Link" />
      <UrlField source="culture_link" content="Link" />
      <TextField source="location" />
      <TextField source="auth0_id" />
      <BooleanField source="is_admin" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const UserShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="name" />
      <UrlField source="profile_picture_link" content="Link" />
      <UrlField source="linkedin" content="Link" />
      <UrlField source="culture_link" content="Link" />
      <TextField source="location" />
      <TextField source="auth0_id" />
      <BooleanField source="is_admin" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="name" />
      <TextInput source="profile_picture_link" />
      <TextInput source="linkedin" />
      <TextInput source="culture_link" />
      <TextInput source="location" />
      <TextInput source="auth0_id" />
      <BooleanInput source="is_admin" />
    </SimpleForm>
  </Create>
);

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="email" />
      <TextInput source="name" />
      <TextInput source="profile_picture_link" />
      <TextInput source="linkedin" />
      <TextInput source="culture_link" />
      <TextInput source="location" />
      <TextInput source="auth0_id" />
      <BooleanInput source="is_admin" />
    </SimpleForm>
  </Edit>
);
