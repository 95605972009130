import { useEffect } from "react";

const Error401Page = () => {
  useEffect(() => {
    document.title = "Venturous";
  }, []);

  return (
    <div className="flex flex-col gap-4 max-w-[800px] text-center justify-center m-auto min-h-full">
      <h1 className="text-4xl font-semibold text-[#22234A]">
        Unauthorized Access
      </h1>
      <p className="text-lg text-[#727E94]">
        You do not have permission to view this page. If you believe that this
        message has been shown in error, contact your support.
      </p>
    </div>
  );
};

export default Error401Page;
