import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";

const filters = [
  <ReferenceInput
    key="job_opening"
    source="job_opening"
    reference="job_openings"
  />,
  <TextInput key="name" source="name" />,
  <NumberInput key="duration_in_minutes" source="duration_in_minutes" />,
  <NumberInput key="step" source="step" />,
  <DateInput key="created_at" source="created_at" />,
];

export const JobOpeningInterviewList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="job_opening" reference="job_openings" />
      <TextField source="name" />
      <NumberField source="duration_in_minutes" />
      <NumberField source="step" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const JobOpeningInterviewShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="job_opening" reference="job_openings" />
      <TextField source="name" />
      <NumberField source="duration_in_minutes" />
      <NumberField source="step" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const JobOpeningInterviewCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="job_opening" reference="job_openings" />
      <TextInput source="name" />
      <NumberInput source="duration_in_minutes" />
      <NumberInput source="step" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const JobOpeningInterviewEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="job_opening" reference="job_openings" />
      <TextInput source="name" />
      <NumberInput source="duration_in_minutes" />
      <NumberInput source="step" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
