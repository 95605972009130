import { useEffect } from "react";
import { Link } from "@fluentui/react-components";

const ErrorNotInvitedPage = () => {
  useEffect(() => {
    document.title = "Venturous";
  }, []);

  return (
    <div className="flex flex-col gap-4 max-w-[800px] text-center justify-center m-auto min-h-full">
      <h1 className="text-4xl font-semibold text-[#22234A]">
        Venturous is an invite-only platform.
      </h1>
      <p className="text-xl text-[#22234A]">
        If you are interested in joining as a candidate, you can apply{" "}
        <Link
          className="!text-[#ed5c38]"
          href="https://5rgl6vtej1v.typeform.com/to/G7vw11AM"
          target="_blank"
        >
          here
        </Link>
        . If you have already applied and been accepted, please make sure you
        have signed up using the same email address that you applied with.
      </p>
      <p className="text-xl text-[#22234A]">
        If you are interested in working with Venturous to fill roles at your
        company, please email{" "}
        <Link
          className="!text-[#ed5c38]"
          href="mailto:kevin@myventurous.com"
          target="_blank"
        >
          kevin@myventurous.com
        </Link>{" "}
        or schedule a time using this{" "}
        <Link
          className="!text-[#ed5c38]"
          href="https://calendly.com/kevin-venturous/30min"
          target="_blank"
        >
          link
        </Link>
        . If your company is already on Venturous, please request an invite from
        a member of your organization.
      </p>
      <p className="text-xl text-[#22234A]">
        If you believe you are seeing this message in error, please contact
        support at{" "}
        <Link
          className="!text-[#ed5c38]"
          href="mailto:dev@myventurous.com"
          target="_blank"
        >
          dev@myventurous.com
        </Link>
        .
      </p>
    </div>
  );
};

export default ErrorNotInvitedPage;
