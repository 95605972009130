import { Company } from "./redux/features/companyProfileSlice";

const userTypeList = ["Admin", "Candidate", "Company"] as const;
export type UserType = (typeof userTypeList)[number];

/* Candidate */
export const candidateWaitingRoomStatusList = [
  "New",
  "Invited to Apply",
  "Application Under Review",
  "Taking/Sent MAP",
  "MAP Under Review",
  "Interviewing",
  "Interview Under Review",
  "Joined",
  "Dropped out",
  "Rejected",
] as const;
export type CandidateWaitingRoomStatus =
  (typeof candidateWaitingRoomStatusList)[number];

/* Job Opening */
export interface CompanyJobOpening {
  id?: string;
  companyRecordId?: string;
  role?: string;
  jobDescriptionLink?: string;
  shortDescription?: string;
  keyResponsibilities?: string;
  scope?: string;
  budget?: string;
  desiredStartDate?: string;
  convertsToFullTime?: ConvertsToFullTime;
  matchCriteria1?: string;
  matchCriteria2?: string;
  matchCriteria3?: string;
  noteForVenturous?: string;
  status?: JobOpeningStatus;
  candidates?: CandidateStatus[];
  interviews?: CompanyInterview[];
}

export interface CreateOrUpdateCompanyJobOpening
  extends Omit<
    CompanyJobOpening,
    "id" | "companyRecordId" | "status" | "candidates" | "interviews"
  > {
  interviews?: CreateOrUpdateInterview[];
}

export interface CandidateJobOpening {
  id: string;
  role?: string;
  jobDescriptionLink?: string;
  shortDescription?: string;
  keyResponsibilities?: string;
  scope?: string;
  budget?: string;
  desiredStartDate?: string;
  convertsToFullTime?: ConvertsToFullTime;
  matchCriteria1?: string;
  matchCriteria2?: string;
  matchCriteria3?: string;
  status?: JobOpeningStatus;
  candidateStatus: MinimalCandidateStatus;
  interviews?: CandidateInterview[];
  company?: Company;
}

export const convertsToFullTimeList = ["Yes", "No", "Unsure"] as const;
export type ConvertsToFullTime = (typeof convertsToFullTimeList)[number];

export const jobOpeningStatusList = [
  "New Opening",
  "Creating Job Spec",
  "Identifying Candidates",
  "Presenting Shortlist",
  "Role Filled (won)",
  "Role Filled (lost)",
] as const;
export type JobOpeningStatus = (typeof jobOpeningStatusList)[number];

/* Candidate Status */
export interface CandidateStatus {
  id: string;
  candidateId: string;
  name?: string;
  headline?: string;
  profilePictureLink?: string;
  status?: CandidateApplicationStatus;
  scope?: string;
  agreesWithScope?: boolean;
  budget?: string;
  agreesWithPay?: boolean;
  earliestStartDate?: string;
  agreesWithStartDate?: boolean;
  openToFullTime?: boolean;
}

export interface MinimalCandidateStatus {
  id: string;
  jobOpeningId: string;
  status?: CandidateApplicationStatus;
  scope?: string;
  agreesWithScope?: boolean;
  budget?: string;
  agreesWithPay?: boolean;
  earliestStartDate?: string;
  agreesWithStartDate?: boolean;
  openToFullTime?: boolean;
  matchCriteria1?: string;
  matchCriteria1Quote?: string;
  matchCriteria2?: string;
  matchCriteria2Quote?: string;
  matchCriteria3?: string;
  matchCriteria3Quote?: string;
}

export interface CandidateAcceptOpportunityData {
  agreesWithScope: boolean;
  agreesWithPay: boolean;
  agreesWithStartDate: boolean;
  openToFullTime: boolean;
  scope?: string;
  budget?: string;
  earliestStartDate?: string;
  matchCriteria1Quote?: string;
  matchCriteria2Quote?: string;
  matchCriteria3Quote?: string;
  matchCriteria1Suggestion?: string;
  matchCriteria2Suggestion?: string;
  matchCriteria3Suggestion?: string;
}

export interface CandidateDeclineOpportunityData {
  declineReasons: CandidateDeclineReason[];
  otherDeclineReason?: string;
}

export const candidateApplicationStatusList = [
  "Identified",
  "Checking Interest",
  "Screening",
  "Possible Fit",
  "Shortlisted",
  "Accepted by Client",
  "Rejected for Role",
  "Not Interested",
] as const;
export type CandidateApplicationStatus =
  (typeof candidateApplicationStatusList)[number];

export const candidateDeclineReasonList = [
  "Compensation",
  "Requirements or skill mismatch",
  "Too many hours",
  "Too few hours",
  "Start date doesn't align",
  "Lack of industry fit",
  "Lack of availability",
] as const;
export type CandidateDeclineReason =
  (typeof candidateDeclineReasonList)[number];

/* Interview */
export interface CreateOrUpdateInterview {
  id?: string;
  name: string;
  duration: number;
  participants: string[];
}

export interface CompanyInterview {
  id: string;
  name: string;
  duration: number;
  participants: string[];
  step: number;
  interviewRequested: string[];
  interviewCompleted: string[];
}

export interface CandidateInterview {
  id: string;
  name?: string;
  duration?: number;
  step?: number;
  interviewRequested: boolean;
  interviewCompleted: boolean;
}

/* SVG */
export type SvgJsxType = (props: {
  className?: string;
  height?: string;
  width?: string;
  fill?: string;
  stroke?: string;
  onClick?: () => void;
}) => JSX.Element;

/* Error Payload */
export interface ErrorPayload {
  error: ErrorType;
  message: string;
}

export type ErrorType =
  | "UNEXPECTED"
  | "UNAUTHORIZED"
  | "SERVER_ERROR"
  | "GENERIC_BAD_INPUT"
  | "SPECIFIC_BAD_INPUT";
