import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EmailField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import {
  referenceRelationshipList,
  referenceStatusList,
} from "../../redux/features/candidateProfileSlice";

const relationshipChoices = referenceRelationshipList.map((type) => ({
  id: type,
  name: type,
}));
const statusChoices = referenceStatusList.map((type) => ({
  id: type,
  name: type,
}));

const filters = [
  <ReferenceInput key="user" source="user" reference="users" />,
  <TextInput key="name" source="name" />,
  <TextInput key="title" source="title" />,
  <TextInput key="company" source="company" />,
  <SelectInput
    key="relationship"
    source="relationship"
    choices={relationshipChoices}
  />,
  <SelectInput key="status" source="status" choices={statusChoices} />,
  <DateInput key="created_at" source="created_at" />,
];

export const ReferenceList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="user" reference="users" />
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="company" />
      <SelectField source="relationship" choices={relationshipChoices} />
      <SelectField source="status" choices={statusChoices} />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const ReferenceShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="user" reference="users" />
      <TextField source="name" />
      <TextField source="title" />
      <TextField source="company" />
      <SelectField source="relationship" choices={relationshipChoices} />
      <EmailField source="email" />
      <TextField source="request_message" />
      <SelectField source="status" choices={statusChoices} />
      <TextField source="response_1" />
      <TextField source="response_2" />
      <TextField source="response_3" />
      <TextField source="response_4" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const ReferenceCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" />
      <TextInput source="name" />
      <TextInput source="title" />
      <TextInput source="company" />
      <SelectInput source="relationship" choices={relationshipChoices} />
      <TextInput source="email" />
      <TextInput source="request_message" />
      <SelectInput source="status" choices={statusChoices} />
      <TextInput source="response_1" />
      <TextInput source="response_2" />
      <TextInput source="response_3" />
      <TextInput source="response_4" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const ReferenceEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" />
      <TextInput source="name" />
      <TextInput source="title" />
      <TextInput source="company" />
      <SelectInput source="relationship" choices={relationshipChoices} />
      <TextInput source="email" />
      <TextInput source="request_message" />
      <SelectInput source="status" choices={statusChoices} />
      <TextInput source="response_1" />
      <TextInput source="response_2" />
      <TextInput source="response_3" />
      <TextInput source="response_4" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
