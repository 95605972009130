import { useRecordContext } from "react-admin";

// Link to a candidate profile
export const CandidateProfileField = () => {
  const record = useRecordContext();
  const data: unknown = record?.user;

  if (data && typeof data === "string") {
    return (
      <a href={`/candidate/${data}/edit`} target="_blank" rel="noreferrer">
        Link
      </a>
    );
  }

  return <span>Missing ID!</span>;
};

// Link to a company profile
export const CompanyProfileField = () => {
  const record = useRecordContext();
  const data: unknown = record?.id;

  if (data && typeof data === "string") {
    return (
      <a href={`/company/${data}`} target="_blank" rel="noreferrer">
        Link
      </a>
    );
  }

  return <span>Missing ID!</span>;
};

// Link to a candidate opportunity profile
export const CandidateOpportunityProfileField = () => {
  const record = useRecordContext();
  const opening: unknown = record?.job_opening;
  const application: unknown = record?.id;

  if (
    opening &&
    application &&
    typeof opening === "string" &&
    typeof application === "string"
  ) {
    return (
      <a
        href={`/opening/${opening}/application/${application}`}
        target="_blank"
        rel="noreferrer"
      >
        Link
      </a>
    );
  }

  return <span>Missing ID!</span>;
};

// Link to a company claim link
export const CompanyClaimField = () => {
  const record = useRecordContext();
  const companyId: unknown = record?.id;

  if (companyId && typeof companyId === "string") {
    return (
      <a href={`/company/${companyId}/claim`} target="_blank" rel="noreferrer">
        Link
      </a>
    );
  }

  return <span>Missing ID!</span>;
};

// A list of array elements
export const SelectArrayField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const data: unknown = record?.[source];

  if (!Array.isArray(data)) {
    return <span />;
  }

  return <span>{data.join(", ")}</span>;
};

export const StringArrayField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const data: unknown = record?.[source];
  const id = record?.id;

  if (Array.isArray(data) && typeof data[0] === "string") {
    return (
      <ul>
        {data.map((item, idx) => (
          <li key={`${id}-${source}-${idx}`}>{item}</li>
        ))}
      </ul>
    );
  }

  return <span />;
};

CandidateProfileField.defaultProps = { label: "Profile" };
CompanyProfileField.defaultProps = { label: "Profile" };
CompanyClaimField.defaultProps = { label: "Claim Link" };
CandidateOpportunityProfileField.defaultProps = { label: "Profile" };
