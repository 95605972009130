import { EllipseSVG } from "../assets/svgs";

const ProgressTracker = ({
  stages,
  currentStage,
}: {
  stages: string[];
  currentStage: number;
}) => {
  return (
    <div className="flex self-stretch items-stretch gap-10">
      {stages.map((stage, idx) => {
        const isPastStage = idx < currentStage;
        const isCurrentStage = idx === currentStage;

        let className = "";
        let ellipseProps = {};

        if (isPastStage) {
          className = "complete";
          ellipseProps = {
            stroke: "#19A594",
            fill: "#19A594",
          };
        } else if (isCurrentStage) {
          className = "active";
          ellipseProps = {
            stroke: "#19A594",
          };
        }

        return (
          <div
            key={`progress-stage-${idx}`}
            className={`progress-bar-item ${className} ${idx !== 0 && idx !== stages.length - 1 ? "flex-1" : ""}`}
          >
            <p>{stage}</p>
            <EllipseSVG className="progress-bar-circle" {...ellipseProps} />
          </div>
        );
      })}
    </div>
  );
};

export default ProgressTracker;
