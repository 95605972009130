import { Admin, Resource } from "react-admin";
import {
  UserCreate,
  UserEdit,
  UserList,
  UserShow,
} from "../elements/admin/Users";
import {
  CandidateCreate,
  CandidateEdit,
  CandidateList,
  CandidateShow,
} from "../elements/admin/Candidates";
import {
  // ReferenceCreate,
  ReferenceEdit,
  ReferenceList,
  ReferenceShow,
} from "../elements/admin/References";
import {
  CompanyCreate,
  CompanyEdit,
  CompanyList,
  CompanyShow,
} from "../elements/admin/Companies";
import {
  CompanyUserCreate,
  CompanyUserEdit,
  CompanyUserList,
  CompanyUserShow,
} from "../elements/admin/CompanyUsers";
import {
  JobOpeningCreate,
  JobOpeningEdit,
  JobOpeningList,
  JobOpeningShow,
} from "../elements/admin/JobOpenings";
import {
  // JobOpeningInterviewCreate,
  // JobOpeningInterviewEdit,
  JobOpeningInterviewList,
  JobOpeningInterviewShow,
} from "../elements/admin/JobOpeningInterviews";
import {
  JobOpeningInterviewRequestCreate,
  JobOpeningInterviewRequestEdit,
  JobOpeningInterviewRequestList,
  JobOpeningInterviewRequestShow,
} from "../elements/admin/JobOpeningInterviewRequests";
import {
  CandidateStatusCreate,
  CandidateStatusEdit,
  CandidateStatusList,
  CandidateStatusShow,
} from "../elements/admin/CandidateStatuses";
import dataProvider from "../utils/dataProvider";

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
const ReactAdmin = () => (
  <Admin basename="/admin" dataProvider={dataProvider}>
    <Resource
      name="users"
      list={UserList}
      edit={UserEdit}
      create={UserCreate}
      show={UserShow}
    />
    <Resource
      name="candidates"
      list={CandidateList}
      edit={CandidateEdit}
      create={CandidateCreate}
      show={CandidateShow}
      recordRepresentation={(record) => record?.users?.name ?? record?.id}
    />
    <Resource
      name="references"
      list={ReferenceList}
      edit={ReferenceEdit}
      // create={ReferenceCreate}
      show={ReferenceShow}
    />
    <Resource
      name="companies"
      list={CompanyList}
      edit={CompanyEdit}
      create={CompanyCreate}
      show={CompanyShow}
    />
    <Resource
      name="company_users"
      list={CompanyUserList}
      edit={CompanyUserEdit}
      create={CompanyUserCreate}
      show={CompanyUserShow}
      recordRepresentation={(record) =>
        record?.users?.name && record?.companies?.name
          ? `${record.companies.name} <> ${record.users.name}`
          : record?.id
      }
    />
    <Resource
      name="job_openings"
      list={JobOpeningList}
      edit={JobOpeningEdit}
      create={JobOpeningCreate}
      show={JobOpeningShow}
      recordRepresentation={(record) => {
        return record?.companies?.name
          ? `${record.companies.name} | ${record?.role}`
          : record?.role;
      }}
    />
    <Resource
      name="job_opening_interviews"
      list={JobOpeningInterviewList}
      // edit={JobOpeningInterviewEdit}
      // create={JobOpeningInterviewCreate}
      show={JobOpeningInterviewShow}
      recordRepresentation={(record) => {
        return record?.job_openings?.companies?.name
          ? `${record.job_openings.companies.name} | ${record.job_openings?.role}`
          : record?.id;
      }}
    />
    <Resource
      name="job_opening_interview_requests"
      list={JobOpeningInterviewRequestList}
      edit={JobOpeningInterviewRequestEdit}
      create={JobOpeningInterviewRequestCreate}
      show={JobOpeningInterviewRequestShow}
    />
    <Resource
      name="candidate_statuses"
      list={CandidateStatusList}
      edit={CandidateStatusEdit}
      create={CandidateStatusCreate}
      show={CandidateStatusShow}
    />
  </Admin>
);
/* eslint-enable @typescript-eslint/no-unsafe-return */
/* eslint-enable @typescript-eslint/no-unsafe-member-access */

export default ReactAdmin;
