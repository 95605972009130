import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

const networkErrorHandler = <T>(
  error: AxiosError,
  rejectWithValue: (value: { data: unknown; status: number }) => T,
): T => {
  if (error.name === "CanceledError") {
    throw error;
  }

  if (!error.response) {
    Sentry.captureException(error);
    throw error;
  }

  return rejectWithValue({
    data: error.response.data,
    status: error.response.status,
  });
};

export default networkErrorHandler;
