import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useButtonStyles = makeStyles({
  redButton: {
    backgroundColor: tokens.colorPaletteRedForeground3,
    color: "white",
    ...shorthands.borderColor(tokens.colorPaletteRedForeground3),
    "&:hover": {
      backgroundColor: tokens.colorPaletteRedForeground1,
      color: "white",
      ...shorthands.borderColor(tokens.colorPaletteRedForeground1),
    },
  },
});

export const useDialogStyles = makeStyles({
  editDialog: {
    ...shorthands.padding(0),
    "& > .fui-DialogBody": {
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("16px"),
      "& > .fui-DialogTitle": {
        paddingTop: "24px",
      },
      "& > .fui-DialogContent": {
        display: "flex",
        flexDirection: "column",
        ...shorthands.padding(0, "24px"),
        ...shorthands.gap("20px"),
        ":not(&:has(+ .fui-DialogActions))": {
          paddingBottom: "24px",
        },
      },
      "& > .fui-DialogActions": {
        paddingBottom: "24px",
      },
    },
  },
  wideDialog: {
    width: "90%",
    maxWidth: "1200px",
  },
});

export const useFieldStyles = makeStyles({
  editField: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("8px"),
    "& > label": {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      color: "#717E94",
    },
    "& .fui-Textarea textarea": {
      ...shorthands.padding("12px", "16px"),
    },
    "&:has( span input[type='checkbox'])": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      ...shorthands.gap("4px"),
    },
    "& input[type='date']": {
      width: "fit-content",
      ...shorthands.padding("12px", "8px"),
      ...shorthands.borderRadius("12px"),
      ...shorthands.border("1px", "solid", "#E8E8E8"),
    },
    "& input[type='number']": {
      width: "fit-content",
      ...shorthands.padding("12px", "8px"),
    },
  },
  largeDropdown: {
    "& > button": {
      ...shorthands.padding("12px", "8px"),
    },
  },
});

export const useMenuStyles = makeStyles({
  userButton: {
    cursor: "pointer",
    marginRight: "40px",
  },
  menuButton: {
    "& .fui-MenuButton__menuIcon": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
    },
  },
});

export const useInputStyles = makeStyles({
  largeTextarea: {
    "& > .fui-Textarea__textarea": {
      height: "150px",
      maxHeight: "250px",
    },
  },
  hugeTextArea: {
    "& > .fui-Textarea__textarea": {
      height: "250px",
      maxHeight: "400px",
    },
  },
});
