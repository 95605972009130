import { useState } from "react";
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import { IReference } from "../redux/features/candidateProfileSlice";
import { useDialogStyles } from "../fluentStyles";
import { CloseCircleSVG } from "../assets/svgs";

interface ReferenceProps extends IReference {
  candidateName?: string;
  isLast?: boolean;
}

const RESPONSE_TO_PROMPT_MAP = {
  managerResponse1: `How long have you known them?`,
  managerResponse2: `In what capacity have you worked together?`,
  managerResponse3: `Would you rehire them? Why or Why not?`,
  managerResponse4: `Were there any areas where they particularly excelled?`,
  directReportResponse1: `How do you know them, and in what capacity have you worked together?`,
  directReportResponse2: `Can you describe their key responsibilities?`,
  directReportResponse3: `Would you work for this manager again?`,
  directReportResponse4: `Were there any areas where they particularly excelled?`,
  clientResponse1: `How do you know them, and in what capacity have you worked together?`,
  clientResponse2: `Would you want them to support your business again?`,
  clientResponse3: `In your opinion, what are their strongest professional skills or attributes?`,
  clientResponse4: `Did they meet or exceed your expectations?`,
  peerCoworkerResponse1: `How long have you known them?`,
  peerCoworkerResponse2: `In what capacity have you worked together?`,
  peerCoworkerResponse3: `Would you want to work with them again?`,
  peerCoworkerResponse4: `Were there any areas where they particularly excelled?`,
  boardMemberInvestorResponse1: `How do you know them, and in what capacity have you worked together?`,
  boardMemberInvestorResponse2: `Can you describe their key responsibilities?`,
  boardMemberInvestorResponse3: `Would you want them to support your business again?`,
  boardMemberInvestorResponse4: `In your opinion, what are their strongest professional skills or attributes?`,
} as const;

const ReferenceResponse = ({
  question,
  answer,
}: {
  question: string;
  answer?: string;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="text-sm font-semibold text-[#22234A] leading-6">
        {question}
      </p>
      <p className="text-sm font-normal text-[#4E4F6C] leading-5">{answer}</p>
    </div>
  );
};

const Reference = ({
  candidateName,
  name,
  title,
  company,
  relationship,
  managerResponse1,
  managerResponse2,
  managerResponse3,
  managerResponse4,
  directReportResponse1,
  directReportResponse2,
  directReportResponse3,
  directReportResponse4,
  clientResponse1,
  clientResponse2,
  clientResponse3,
  clientResponse4,
  peerCoworkerResponse1,
  peerCoworkerResponse2,
  peerCoworkerResponse3,
  peerCoworkerResponse4,
  boardMemberInvestorResponse1,
  boardMemberInvestorResponse2,
  boardMemberInvestorResponse3,
  boardMemberInvestorResponse4,
  status,
  isLast,
}: ReferenceProps) => {
  const [referenceOpen, setReferenceOpen] = useState(false);
  const dialogStyle = useDialogStyles();

  const isComplete = status === "Approved";

  let questionAnswerGroups: JSX.Element[] = [];
  let quote = "";
  if (relationship === "Manager") {
    quote = managerResponse4 ?? "";
    questionAnswerGroups = [
      <ReferenceResponse
        key={`${name}-${relationship}-manager-1`}
        question={RESPONSE_TO_PROMPT_MAP.managerResponse1}
        answer={managerResponse1}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-manager-2`}
        question={RESPONSE_TO_PROMPT_MAP.managerResponse2}
        answer={managerResponse2}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-manager-3`}
        question={RESPONSE_TO_PROMPT_MAP.managerResponse3}
        answer={managerResponse3}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-manager-4`}
        question={RESPONSE_TO_PROMPT_MAP.managerResponse4}
        answer={managerResponse4}
      />,
    ];
  } else if (relationship === "Client") {
    quote = clientResponse3 ?? "";
    questionAnswerGroups = [
      <ReferenceResponse
        key={`${name}-${relationship}-client-1`}
        question={RESPONSE_TO_PROMPT_MAP.clientResponse1}
        answer={clientResponse1}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-manager-2`}
        question={RESPONSE_TO_PROMPT_MAP.clientResponse2}
        answer={clientResponse2}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-manager-3`}
        question={RESPONSE_TO_PROMPT_MAP.clientResponse3}
        answer={clientResponse3}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-manager-4`}
        question={RESPONSE_TO_PROMPT_MAP.clientResponse4}
        answer={clientResponse4}
      />,
    ];
  } else if (relationship === "Direct Report") {
    quote = directReportResponse4 ?? "";
    questionAnswerGroups = [
      <ReferenceResponse
        key={`${name}-${relationship}-direct-report-1`}
        question={RESPONSE_TO_PROMPT_MAP.directReportResponse1}
        answer={directReportResponse1}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-direct-report-2`}
        question={RESPONSE_TO_PROMPT_MAP.directReportResponse2}
        answer={directReportResponse2}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-direct-report-3`}
        question={RESPONSE_TO_PROMPT_MAP.directReportResponse3}
        answer={directReportResponse3}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-direct-report-4`}
        question={RESPONSE_TO_PROMPT_MAP.directReportResponse4}
        answer={directReportResponse4}
      />,
    ];
  } else if (relationship === "Peer" || relationship === "Coworker") {
    quote = peerCoworkerResponse4 ?? "";
    questionAnswerGroups = [
      <ReferenceResponse
        key={`${name}-${relationship}-peer-1`}
        question={RESPONSE_TO_PROMPT_MAP.peerCoworkerResponse1}
        answer={peerCoworkerResponse1}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-peer-2`}
        question={RESPONSE_TO_PROMPT_MAP.peerCoworkerResponse2}
        answer={peerCoworkerResponse2}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-peer-3`}
        question={RESPONSE_TO_PROMPT_MAP.peerCoworkerResponse3}
        answer={peerCoworkerResponse3}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-peer-4`}
        question={RESPONSE_TO_PROMPT_MAP.peerCoworkerResponse4}
        answer={peerCoworkerResponse4}
      />,
    ];
  } else if (relationship === "Board Member" || relationship === "Investor") {
    quote = boardMemberInvestorResponse4 ?? "";
    questionAnswerGroups = [
      <ReferenceResponse
        key={`${name}-${relationship}-investor-1`}
        question={RESPONSE_TO_PROMPT_MAP.boardMemberInvestorResponse1}
        answer={boardMemberInvestorResponse1}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-investor-2`}
        question={RESPONSE_TO_PROMPT_MAP.boardMemberInvestorResponse2}
        answer={boardMemberInvestorResponse2}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-investor-3`}
        question={RESPONSE_TO_PROMPT_MAP.boardMemberInvestorResponse3}
        answer={boardMemberInvestorResponse3}
      />,
      <ReferenceResponse
        key={`${name}-${relationship}-investor-4`}
        question={RESPONSE_TO_PROMPT_MAP.boardMemberInvestorResponse4}
        answer={boardMemberInvestorResponse4}
      />,
    ];
  } else {
    return;
  }

  const heading = (
    <div className="flex flex-col items-start self-stretch">
      <p className="text-sm font-semibold text-[#22234A]">{name}</p>
      <div className="flex gap-2 items-center">
        <p className="text-sm font-medium text-[#727E94]">{`${relationship} when ${candidateName?.split(" ")?.[0] ?? "candidate"} was a ${title} @${company}`}</p>
      </div>
    </div>
  );

  const dialog = (
    <Dialog
      open={referenceOpen}
      onOpenChange={(_evt, data) => setReferenceOpen(data.open)}
    >
      <DialogSurface className={dialogStyle.editDialog}>
        <DialogBody>
          <DialogTitle>
            <div className="flex self-stretch items-center justify-between pb-4 px-6 border-bottom-line">
              <p className="text-lg font-semibold tracking-[-0.18px]">
                Reference
              </p>
              <DialogTrigger disableButtonEnhancement>
                <CloseCircleSVG
                  className="cursor-pointer"
                  height="20"
                  width="20"
                />
              </DialogTrigger>
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-3 pb-4">
              {heading}
              {questionAnswerGroups}
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  return (
    <>
      <div
        className={`flex-1 flex flex-col gap-3 items-start self-stretch py-4 ${isLast ? "" : "border-bottom-line"} ${isComplete ? "cursor-pointer" : "opacity-75"}`}
        onClick={() => (isComplete ? setReferenceOpen(true) : null)}
      >
        {heading}
        {isComplete ? (
          <>
            <p className="text-sm font-semibold text-[#22234A] leading-6">
              {`${candidateName?.split(" ")?.[0] ?? "candidate"}'s Strengths`}
            </p>
            <p className="text-sm font-medium text-[#4E4F6C]">{quote}</p>
          </>
        ) : (
          <div className="flex gap-[10px] content-center items-center py-1 px-2 rounded-[20px] bg-yellow-100 border border-solid border-yellow-100/30">
            <p className="text-xs font-medium text-[#22234A]">Request Sent</p>
          </div>
        )}
      </div>
      {dialog}
    </>
  );
};

export default Reference;
