const Project = ({
  title,
  summary,
  link,
  imageLink,
  isLast,
  openEditFn,
}: {
  title?: string;
  summary?: string;
  link?: string;
  imageLink?: string;
  isLast?: boolean;
  openEditFn?: () => void;
}) => {
  return (
    <div
      className={`flex flex-col gap-3 items-start self-stretch py-4 ${isLast ? "" : "border-bottom-line"}`}
    >
      <div className="flex gap-3 items-center self-stretch">
        {imageLink ? (
          <div
            className="size-10 min-w-10 min-h-10 rounded-full"
            style={{
              backgroundImage: `url(${imageLink})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          />
        ) : null}
        <div className="flex flex-col gap-1 items-start flex-1">
          <p className="text-base font-semibold text-[#22234A]">{title}</p>
          <p className="text-xs font-medium text-[#4E4F6C] overflow-hidden text-ellipsis">
            {summary}
          </p>
        </div>
        {openEditFn ? (
          <p className="edit-text self-start" onClick={openEditFn}>
            Edit
          </p>
        ) : null}
      </div>
      <div className="flex gap-2 justify-center items-center">
        <a
          className="text-sm font-semibold tracking-[-0.14px] text-[#ED5C38] hover:text-[#ED5C38] hover:underline"
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          View Project
        </a>
      </div>
    </div>
  );
};

export default Project;
