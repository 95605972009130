import {
  ArrayInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import { StringArrayField } from "./CustomFields";
import { convertsToFullTimeList, jobOpeningStatusList } from "../../types";

const convertsToFullTimeChoices = convertsToFullTimeList.map((type) => ({
  id: type,
  name: type,
}));
const statusChoices = jobOpeningStatusList.map((type) => ({
  id: type,
  name: type,
}));

const filters = [
  <ReferenceInput key="company" source="company" reference="companies" />,
  <TextInput key="role" source="role" />,
  <SelectInput key="status" source="status" choices={statusChoices} />,
  <TextInput key="scope" source="scope" />,
  <TextInput key="budget" source="budget" />,
  <DateInput key="desired_start_date" source="desired_start_date" />,
  <SelectInput
    key="converts_to_full_time"
    source="converts_to_full_time"
    choices={convertsToFullTimeChoices}
  />,
  <DateInput key="created_at" source="created_at" />,
];

export const JobOpeningList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="company" reference="companies" />
      <TextField source="role" />
      <TextField source="job_description_link" />
      <SelectField source="status" choices={statusChoices} />
      <TextField source="scope" />
      <TextField source="budget" />
      <DateField source="desired_start_date" />
      <SelectField
        source="converts_to_full_time"
        choices={convertsToFullTimeChoices}
      />
      {/* <StringArrayField source="match_criteria" />
      <TextField source="short_description" />
      <TextField source="key_responsibilities" />
      <TextField source="note_for_venturous" /> */}
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const JobOpeningShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="company" reference="companies" />
      <TextField source="role" />
      <TextField source="job_description_link" />
      <SelectField source="status" choices={statusChoices} />
      <TextField source="scope" />
      <TextField source="budget" />
      <DateField source="desired_start_date" />
      <SelectField
        source="converts_to_full_time"
        choices={convertsToFullTimeChoices}
      />
      <StringArrayField source="match_criteria" />
      <TextField source="short_description" />
      <TextField source="key_responsibilities" />
      <TextField source="note_for_venturous" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const JobOpeningCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="company" reference="companies" />
      <TextInput source="role" />
      <TextInput source="job_description_link" />
      <SelectInput source="status" choices={statusChoices} />
      <TextInput source="scope" />
      <TextInput source="budget" />
      <DateInput source="desired_start_date" />
      <SelectInput
        source="converts_to_full_time"
        choices={convertsToFullTimeChoices}
      />
      <ArrayInput source="match_criteria">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="short_description" multiline />
      <TextInput source="key_responsibilities" multiline />
      <TextInput source="note_for_venturous" multiline />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const JobOpeningEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="company" reference="companies" />
      <TextInput source="role" />
      <TextInput source="job_description_link" />
      <SelectInput source="status" choices={statusChoices} />
      <TextInput source="scope" />
      <TextInput source="budget" />
      <DateInput source="desired_start_date" />
      <SelectInput
        source="converts_to_full_time"
        choices={convertsToFullTimeChoices}
      />
      <ArrayInput source="match_criteria">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="short_description" multiline />
      <TextInput source="key_responsibilities" multiline />
      <TextInput source="note_for_venturous" multiline />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
