import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  UrlField,
} from "react-admin";
import { CompanyClaimField, CompanyProfileField } from "./CustomFields";

const filters = [
  <TextInput key="name" source="name" />,
  <TextInput key="location" source="location" />,
  <TextInput key="logo_link" source="logo_link" />,
  <TextInput key="year_founded" source="year_founded" />,
  <TextInput key="size" source="size" />,
  <TextInput key="funds_raised" source="funds_raised" />,
  <TextInput key="last_fundraising_round" source="last_fundraising_round" />,
  // <TextInput key="culture" source="culture" multiline />,
  <TextInput key="linkedin" source="linkedin" />,
  <TextInput key="website_link" source="website_link" />,
  // <TextInput key="about_us" source="about_us" multiline />,
  // <TextInput key="mission_statement" source="mission_statement" multiline />,
  <DateInput key="created_at" source="created_at" />,
];

export const CompanyList = () => (
  <List filters={filters}>
    <Datagrid>
      <TextField source="name" />
      <CompanyProfileField />
      <CompanyClaimField />
      <TextField source="location" />
      <UrlField source="logo_link" content="Link" />
      <TextField source="year_founded" />
      <TextField source="size" />
      <TextField source="funds_raised" />
      <TextField source="last_fundraising_round" />
      {/* <TextField source="culture" /> */}
      <UrlField source="linkedin" content="Link" />
      <UrlField source="website_link" content="Link" />
      {/* <TextField source="about_us" /> */}
      {/* <TextField source="mission_statement" /> */}
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const CompanyShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <CompanyProfileField />
      <CompanyClaimField />
      <TextField source="location" />
      <UrlField source="logo_link" content="Link" />
      <TextField source="year_founded" />
      <TextField source="size" />
      <TextField source="funds_raised" />
      <TextField source="last_fundraising_round" />
      <TextField source="culture" />
      <UrlField source="linkedin" content="Link" />
      <UrlField source="website_link" content="Link" />
      <TextField source="about_us" />
      <TextField source="mission_statement" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const CompanyCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="location" />
      <TextInput source="logo_link" />
      <TextInput source="year_founded" />
      <TextInput source="size" />
      <TextInput source="funds_raised" />
      <TextInput source="last_fundraising_round" />
      <TextInput source="culture" multiline />
      <TextInput source="linkedin" />
      <TextInput source="website_link" />
      <TextInput source="about_us" multiline />
      <TextInput source="mission_statement" multiline />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const CompanyEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="location" />
      <TextInput source="logo_link" />
      <TextInput source="year_founded" />
      <TextInput source="size" />
      <TextInput source="funds_raised" />
      <TextInput source="last_fundraising_round" />
      <TextInput source="culture" multiline />
      <TextInput source="linkedin" />
      <TextInput source="website_link" />
      <TextInput source="about_us" multiline />
      <TextInput source="mission_statement" multiline />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
