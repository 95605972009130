import { useState, useRef, useLayoutEffect } from "react";

const Biography = ({
  biography,
  openEditFn,
}: {
  biography?: string;
  openEditFn?: () => void;
}) => {
  const ref = useRef(null);
  const [readMore, setReadMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);

  const MAX_LINES = 6;
  const MAX_LINES_CLASS = "line-clamp-6";

  useLayoutEffect(() => {
    if (ref.current) {
      const { offsetHeight, scrollHeight } = ref.current;

      if (readMore) {
        // If readMore is true, check how many lines of text there are
        const lineHeight = parseFloat(getComputedStyle(ref.current).lineHeight);
        const lines = offsetHeight / lineHeight;

        // If there are more than MAX_LINES, then everything is fine
        if (lines > MAX_LINES) {
          return;
        } else {
          // If not, the text is not being truncated anyway and we can reset
          setIsTruncated(false);
          setReadMore(false);
        }
      } else if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
        // If readMore is not true, then check if the text is being cut off by the line clamp
        setIsTruncated(true);
        setReadMore(false);
      } else {
        // In all other cases, reset
        setIsTruncated(false);
        setReadMore(false);
      }
    }
  }, [ref, readMore, biography]);

  return (
    <div className="card gap-3 w-full">
      <div className="flex self-stretch items-center justify-between pb-4 border-bottom-line">
        <div className="flex gap-4 self-stretch items-center">
          <p className="text-lg font-semibold tracking-[-0.18px] text-[#22234A]">
            Biography
          </p>
        </div>
        {openEditFn ? (
          <p className="edit-text" onClick={openEditFn}>
            Edit
          </p>
        ) : null}
      </div>
      <div
        className={`p-3 self-stretch ${openEditFn ? "bg-[#F2F5F5] rounded-xl" : ""}`}
      >
        <p
          ref={ref}
          className={`text-sm font-medium text-[#4E4F6C] whitespace-break-spaces ${readMore ? "" : MAX_LINES_CLASS}`}
        >
          {biography}
        </p>
        {!isTruncated ? null : readMore ? (
          <p className="edit-text w-fit" onClick={() => setReadMore(false)}>
            View less
          </p>
        ) : (
          <p className="edit-text w-fit" onClick={() => setReadMore(true)}>
            View more
          </p>
        )}
      </div>
    </div>
  );
};

export default Biography;
