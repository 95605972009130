import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const filters = [
  <ReferenceInput
    key="interview"
    source="interview"
    reference="job_opening_interviews"
  />,
  <ReferenceInput key="candidate" source="candidate" reference="candidates" />,
  <BooleanInput key="interview_completed" source="interview_completed" />,
  <DateInput key="created_at" source="created_at" />,
];

export const JobOpeningInterviewRequestList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="interview" reference="job_opening_interviews" />
      <ReferenceField source="candidate" reference="candidates" />
      <BooleanField source="interview_completed" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const JobOpeningInterviewRequestShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="interview" reference="job_opening_interviews" />
      <ReferenceField source="candidate" reference="candidates" />
      <BooleanField source="interview_completed" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const JobOpeningInterviewRequestCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="interview" reference="job_opening_interviews" />
      <ReferenceInput source="candidate" reference="candidates" />
      <BooleanInput source="interview_completed" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const JobOpeningInterviewRequestEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="interview" reference="job_opening_interviews" />
      <ReferenceInput source="candidate" reference="candidates" />
      <BooleanInput source="interview_completed" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
