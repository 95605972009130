import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";
import networkErrorHandler from "../../utils/networkErrorHandler";
import {
  CandidateAcceptOpportunityData,
  CandidateDeclineOpportunityData,
  CandidateJobOpening,
  MinimalCandidateStatus,
} from "../../types";

// Define a type for the slice state
interface OpeningState {
  opening?: CandidateJobOpening;
  openings: CandidateJobOpening[];
  status: "idle" | "pending" | "succeeded" | "failed";
  postingStatus: "idle" | "posting" | "succeeded" | "failed";
}

// Define the initial state using that type
const initialState: OpeningState = {
  openings: [],
  status: "idle",
  postingStatus: "idle",
};

export const getCandidateOpenings = createAsyncThunk(
  "candidate/openings/get",
  async (
    { token, signal }: { token: string; signal: AbortSignal },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: CandidateJobOpening[] } = await axios.get(
        `/api/jobOpening/self`,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
          signal,
        },
      );

      return response.data;
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const applyForOpening = createAsyncThunk(
  "candidate/openings/post",
  async (
    {
      openingId,
      candidateStatusId,
      data,
      token,
    }: {
      openingId: string;
      candidateStatusId: string;
      data: CandidateAcceptOpportunityData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: MinimalCandidateStatus } = await axios.post(
        `/api/jobOpening/${openingId}/candidate/${candidateStatusId}/apply`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return {
        id: openingId,
        candidateStatus: response.data,
      };
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const withdrawApplicationForOpening = createAsyncThunk(
  "candidate/openings/post",
  async (
    {
      openingId,
      candidateStatusId,
      data,
      token,
    }: {
      openingId: string;
      candidateStatusId: string;
      data: CandidateDeclineOpportunityData;
      token: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response: { data: MinimalCandidateStatus } = await axios.post(
        `/api/jobOpening/${openingId}/candidate/${candidateStatusId}/withdraw`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token},`,
          },
        },
      );

      return {
        id: openingId,
        candidateStatus: response.data,
      };
    } catch (error) {
      // TODO: Might not even need to bother with this, can just not catch and let errorSlice handle
      if (error instanceof AxiosError) {
        return networkErrorHandler(error, rejectWithValue);
      }
    }
  },
);

export const candidateOpeningSlice = createSlice({
  name: "candidateOpening",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetPostingStatus: (state) => {
      state.postingStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCandidateOpenings.pending, (state) => {
        state.status = "pending";
      })
      .addCase(
        getCandidateOpenings.fulfilled,
        (state, action: PayloadAction<CandidateJobOpening[] | undefined>) => {
          state.openings = action.payload ?? [];
          state.status = "succeeded";
        },
      )
      .addCase(getCandidateOpenings.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(applyForOpening.pending, (state) => {
        state.postingStatus = "posting";
      })
      .addCase(
        applyForOpening.fulfilled,
        (
          state,
          action: PayloadAction<Partial<CandidateJobOpening> | undefined>,
        ) => {
          if (action.payload) {
            const openingIdx = state.openings.findIndex(
              ({ id }) => action.payload?.id === id,
            );
            const opening = state.openings[openingIdx];

            if (opening) {
              const openings = [...state.openings];
              openings[openingIdx] = {
                ...opening,
                ...action.payload,
              };

              state.openings = openings;
            }
          }

          state.postingStatus = "succeeded";
        },
      )
      .addCase(applyForOpening.rejected, (state) => {
        state.postingStatus = "failed";
      });
  },
});

export const { resetPostingStatus } = candidateOpeningSlice.actions;

export default candidateOpeningSlice.reducer;
